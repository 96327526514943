import { getHostName } from '../utils/utils';
import { CUSTOMER } from './customers';

export const VALIDATE_PROMO_CODE = 'validatePromoCode';
export const PROMOCODE = 'promocode';
export const CAMPAIGN = 'campaign';
export const STORE_CAMPAIGN_TO_LOCAL_STORAGE = 'storeCampaignToLocalStorage';
export const FETCH_CAMPAIGN_FROM_LOCAL_STORAGE = 'fetchCampaignFromLocalStorage';

export const state = () => ({});

export const mutations = {};

export const actions = {
  async [VALIDATE_PROMO_CODE](context, promoCode) {
    const { data } = await this.$axios.get(`${getHostName()}/api/validate-promo-code/gcc/${promoCode}`);
    return data;
  },
  async [STORE_CAMPAIGN_TO_LOCAL_STORAGE](context, campaign) {
    localStorage.setItem(CAMPAIGN, JSON.stringify(campaign));
  },
  async [FETCH_CAMPAIGN_FROM_LOCAL_STORAGE]() {
    const campaign = localStorage.getItem(CAMPAIGN);
    return campaign
      ? JSON.parse(localStorage.getItem(CAMPAIGN))
      : null;
  },
};
