export const STRIPE = 'stripe';
export const CREATE_STRIPE_CHECKOUT_SESSION = 'createStripeCheckoutSession';
export const STRIPE_CHECKOUT_SESSION = 'stripeCheckoutSession';

export const state = () => ({});

export const mutations = {};

export const actions = {
  async [CREATE_STRIPE_CHECKOUT_SESSION](context, payload) {
    const { data } = await this.$axios.post('/api/create-stripe-checkout-session/my', payload);
    localStorage.setItem(STRIPE_CHECKOUT_SESSION, JSON.stringify(data));
    return data;
  },
};
