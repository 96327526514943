
var currencyCode = 'MYR';

function calculateCartSubTotal(cartItems) {
  var i, subTotal=0, unitCount=0;

  if (cartItems !== undefined && cartItems !== null) {
    for (i = 0; i < cartItems.length; i++) {
        subTotal += cartItems[i].price * cartItems[i].quantity;
        unitCount += cartItems[i].quantity;
    }
  }

  return {
    subTotal: subTotal,
    unitCount: unitCount
  };
}

function getPageObject() {

  var pageObj = {
    pathName: null,
    title: '',
    productShortCode: ''
  }

  var isProdEnv = (window.location.host.indexOf('dokijuice.com') == -1) ? false : true;
  var pathName = ((window.location.pathname).substr(0,1) == '/') ? (window.location.pathname).substring(1) : window.location.pathname;
  var paths = pathName.split('/');

  if (isProdEnv) { //production/staging

    if (paths[0] == '') { //home page
      pageObj.title = 'Home';
    }
    else if (paths[0] == 'shop') { //shop page

      if (paths[1] == 'lean-up-calorie-burning') {
        if (paths[2] == undefined) {
          pageObj.title = 'Shop Calorie Burning';
        }
        else {
          pageObj.productShortCode = paths[2].toUpperCase();
          pageObj.title = 'Product:' + pageObj.productShortCode;
        }
      }
      else if (paths[1] == 'glow-plus-collagen-anti-photoaging') {
        if (paths[2] == undefined) {
          pageObj.title = 'Shop Collagen Anti-Photoaging';
        }
        else {
          pageObj.productShortCode = paths[2].toUpperCase();
          pageObj.title = 'Product:' + pageObj.productShortCode;
        }
      }
      else if (paths[1] == 'glow-collagen') {
        if (paths[2] == undefined) {
          pageObj.title = 'Shop Collagen';
        }
        else {
          pageObj.productShortCode = paths[2].toUpperCase();
          pageObj.title = 'Product:' + pageObj.productShortCode;
        }
      }
      else if (paths[1] == 'booster-superfood') { 
        if (paths[2] == undefined) {
          pageObj.title = 'Shop Superfood';
        }
        else {
          pageObj.productShortCode = paths[2].toUpperCase();
          pageObj.title = 'Product:' + pageObj.productShortCode;
        }
      }
      else if (paths[1] == 'cadence-energising') { 
        if (paths[2] == undefined) {
          pageObj.title = 'Shop Energising';
        }
        else {
          pageObj.productShortCode = paths[2].toUpperCase();
          pageObj.title = 'Product:' + pageObj.productShortCode;
        }
      }
      else if (paths[1] == 'hype-fitness') { 
        if (paths[2] == undefined) {
          pageObj.title = 'Shop Fitness';
        }
        else {
          pageObj.productShortCode = paths[2].toUpperCase();
          pageObj.title = 'Product:' + pageObj.productShortCode;
        }
      }
      else if (paths[1] == 'gift') { 
        if (paths[2] == undefined) {
          pageObj.title = 'Shop Gift';
        }
        else {
          pageObj.productShortCode = paths[2].toUpperCase();
          pageObj.title = 'Product:' + pageObj.productShortCode;
        }
      }
    }
    else if (paths[0] == 'bag') { //bag page
      pageObj.title = 'Bag';
    }
    else if (paths[0] == 'delivery') { //delivery page
      pageObj.title = 'Delivery';
    }
    else if (paths[0] == 'summary') { //delivery page
      pageObj.title = 'Summary';
    }
    else if (paths[0] == 'order-confirmation') { //delivery page
      pageObj.title = 'Order Confirmation';
    }
    else if (paths[0] == 'order-details') { //delivery page
      pageObj.title = 'Order Details';
    }
  }

  else { //localhost

    var htmlFileName = paths[0].substring(0, paths[0].indexOf('.html'));

    // console.log(htmlFileName);
    if (htmlFileName == '') { //home page
      // pageObj.path = '/';
      pageObj.title = 'Home';
    }
    else if (htmlFileName == 'shop-calorie-burning') { //shop calaorie buring
      pageObj.title = 'Shop Calorie Burning';
    }
    else if (htmlFileName == 'shop-collagen-anti-photoaging') { //shop collagen anti photoagiang
      pageObj.title = 'Shop Collagen Anti-Photoaging';
    }
    else if (htmlFileName == 'shop-collagen') { //shop collagen
      pageObj.title = 'Shop Collagen';
    }
    else if (htmlFileName == 'shop-superfood') { //shop superfood
      pageObj.title = 'Shop Superfood';
    }
    else if (htmlFileName == 'shop-fitness') { //shop fitness
      pageObj.title = 'Shop Fitness';
    }
    else if (htmlFileName == 'shop-gift') { //shop gift
      pageObj.title = 'Shop Gift';
    }
    else if (htmlFileName == 'shop-energising') { //shop energising
      pageObj.title = 'Shop Energising';
    }
    else if (htmlFileName == 'product') { //product page
      var productShortCode = require('query-string').parse(location.search).product;
      pageObj.productShortCode = productShortCode.toUpperCase();
      pageObj.title = 'Product:' + pageObj.productShortCode;
    }
    else if (htmlFileName == 'bag') { //bag page
      pageObj.title = 'Bag';
    }
    else if (htmlFileName == 'delivery') { //delivery page
      pageObj.title = 'Delivery';
    }
    else if (htmlFileName == 'summary') { //delivery page
      pageObj.title = 'Summary';
    }
    else if (htmlFileName == 'order-confirmation') { //delivery page
      pageObj.title = 'Order Confirmation';
    }
    else if (htmlFileName == 'order-details') { //delivery page
      pageObj.title = 'Order Details';
      pageObj.pathName = '/order-details';
    }
  }

  return pageObj;
}

export default {
  //
  // GA/HEAP: Track page view
  //
  trackPageView() {
    var pageObj = getPageObject();
    window.dataLayer.push({
      'event': 'view-page',
      'page-title': pageObj.title,
      'page-path-name': pageObj.pathName
    });
  }
  ,
  //
  // GA/HEAP: Track scroll section
  //
  trackScrollSection(sectionName) {
    var pageObj = getPageObject();
    window.dataLayer.push({
      'event': 'scroll-section',
      'page-section-name': pageObj.title + ':' + sectionName,
      'page-title': pageObj.title,
      'page-path-name': pageObj.pathName
    });
  }
  ,
  // GA/HEAP: Track click to shop page
  //
  trackClickCountry(countryShortCode, sectionName) {
    var pageObj = getPageObject();
    window.dataLayer.push({
      'event': 'click-country',
      'country-short-code': countryShortCode,
      'page-section-name': pageObj.title + ':' + sectionName,
      'page-title': pageObj.title,
      'page-path-name': pageObj.pathName
    });
  }
  ,
  // GA/HEAP: Track click to shop page
  //
  trackClickShop(series, sectionName) {
    var pageObj = getPageObject();
    window.dataLayer.push({
      'event': 'click-shop',
      'product-series': series,
      'page-section-name': pageObj.title + ':' + sectionName,
      'page-title': pageObj.title,
      'page-path-name': pageObj.pathName
    });
  }
  ,
  // GA/HEAP: Track click to product page
  //
  trackClickProduct(series, type, shortCode, sectionName) {
    var pageObj = getPageObject();

    window.dataLayer.push({
      'event': 'click-product',
      'product-series': series,
      'type': type,
      'product-short-code': shortCode,
      'page-section-name': pageObj.title + ':' + sectionName,
      'page-title': pageObj.title,
      'page-path-name': pageObj.pathName
    });
  }
  ,
  // GA/HEAP: Track click to bag page
  //
  trackClickBag(sectionName) {
    var pageObj = getPageObject();
    window.dataLayer.push({
      'event': 'click-bag',
      'page-section-name': pageObj.title + ':' + sectionName,
      'page-title': pageObj.title,
      'page-path-name': pageObj.pathName
    });
  }
  ,
  // GA/HEAP: Track click to community IG
  //
  trackClickCommunityIG(igHandle, sectionName) {
    var pageObj = getPageObject();
    window.dataLayer.push({
      'event': 'click-community-ig',
      'ig-handle': igHandle,
      'page-section-name': pageObj.title + ':' + sectionName,
      'page-title': pageObj.title,
      'page-path-name': pageObj.pathName
    });
  }
  ,
  // GA/HEAP: Track filter product
  //
  trackFilterProduct(series, filterName, sectionName) {
    var pageObj = getPageObject();
    window.dataLayer.push({
      'event': 'filter-product',
      'product-series': series,
      'filter-name': filterName,
      'page-section-name': pageObj.title + ':' + sectionName,
      'page-title': pageObj.title,
      'page-path-name': pageObj.pathName
    });
  }
  ,
  // GA/HEAP: Track view product image
  //
  trackViewProductImage(listIndex, sectionName) {
    var pageObj = getPageObject();
    window.dataLayer.push({
      'event': 'view-product-image',
      'product-image': pageObj.productShortCode + ':' + listIndex,
      'page-section-name': pageObj.title + ':' + sectionName,
      'page-title': pageObj.title,
      'page-path-name': pageObj.pathName
    });
  }
  ,
  // GA/HEAP: Track view collapsed content
  //
  trackViewCollapsedContent(contentName, series, type, sectionName) {
    var pageObj = getPageObject();
    window.dataLayer.push({
      'event': 'view-collapsed-content',
      'content-name': contentName,
      'product-series': series,
      'product-type': type,
      'product-short-code': pageObj.productShortCode,
      'page-section-name': pageObj.title + ':' + sectionName,
      'page-title': pageObj.title,
      'page-path-name': pageObj.pathName
    });
  }
  ,
  // GA/HEAP: Track view science content
  //
  trackViewScienceContent(contentName, series, type, sectionName) {

    var pageObj = getPageObject();
    window.dataLayer.push({
      'event': 'view-science-content',
      'content-name': contentName,
      'product-series': series,
      'product-type': type,
      'product-short-code': pageObj.productShortCode,
      'page-section-name': pageObj.title + ':' + sectionName,
      'page-title': pageObj.title,
      'page-path-name': pageObj.pathName
    });
  }
  ,
  //
  // GA/HEAP: Click checkout
  //
  trackClickCheckout(sectionName) {
    var pageObj = getPageObject();
    window.dataLayer.push({
      'event': 'click-checkout',
      'page-section-name': pageObj.title + ':' + sectionName,
      'page-title': pageObj.title,
      'page-path-name': pageObj.pathName
    });
  }
  ,
  //
  // GA/HEAP: Click review order
  //
  trackClickReviewOrder(isSubscribeNewsletter, sectionName) {
    var pageObj = getPageObject();
    window.dataLayer.push({
      'event': 'click-review-order',
      'is-subscribe-newsletter': isSubscribeNewsletter,
      'page-section-name': pageObj.title + ':' + sectionName,
      'page-title': pageObj.title,
      'page-path-name': pageObj.pathName
    });
  }
  ,
  // //
  // // GA/HEAP: Track apply promo code
  // //
  trackApplyPromoCode(code, sectionName) {
    var pageObj = getPageObject();
    window.dataLayer.push({
      'event': 'apply-promo-code',
      'promo-code-input': code,
      'page-section-name': pageObj.title + ':' + sectionName,
      'page-title': pageObj.title,
      'page-path-name': pageObj.pathName
    });
  }
  ,

  //
  // GAE/HEAP: 0 Track view product details
  //
  trackViewProductPage(itemObj) {
    var pageObj = getPageObject();

    // console.log(itemObj);

    // GAE Product Objects
    var gaeProducts  = [{
      'name': itemObj.productName,
      'id': pageObj.productShortCode,
      'category': itemObj.productSeries
    }];

    window.dataLayer.push({
      'event': 'view-product-page',
      'product-series': itemObj.productSeries,
      'product-type': itemObj.productType,
      'product-name': itemObj.productName,
      'product-short-code': pageObj.productShortCode,
      'page-title': pageObj.title,
      'page-path-name': pageObj.pathName,
      'ecommerce': {
        'detail': {
          'products': gaeProducts
        }
      }
    });
  }
  ,
  //
  // GAE/HEAP: 0 Add to Bag
  //
  trackAddToBag(itemObj) {
    var pageObj = getPageObject();

    // GAE Product Objects
    var gaeProducts  = [{
      'name': itemObj.productName,
      'id': pageObj.productShortCode,
      'price': itemObj.optionPrice,
      'category': itemObj.productSeries,
      'variant': itemObj.optionID,
      'quantity': itemObj.quantity
    }];

    window.dataLayer.push({
      'event': 'add-to-bag',
      'currency-code': currencyCode,
      'product-series': itemObj.productSeries,
      'product-type': itemObj.productType,
      'product-name': itemObj.productName,
      'product-short-code': pageObj.productShortCode,
      'option-id': itemObj.optionID,
      'option-price': itemObj.optionPrice,
      'quantity': itemObj.quantity,
      'item-price': itemObj.itemPrice,
      'currency-code': itemObj.currencyCode,
      'page-title': pageObj.title,
      'page-path-name': pageObj.pathName,
      'ecommerce': {
        'currencyCode': itemObj.currencyCode,
        'add': {
          'products': gaeProducts
        }
      }
    });
  }
  ,
  // GAE/HEAP: 1 Track view bag page
  //
  trackViewBagPage(bagObj, currencyCode) {
    var pageObj = getPageObject();

    // GAE Product Objects
    var gaeProducts = [];
    // console.log(bagObj);
    for(var i=0; i<bagObj.bagItems.length; i++) {
      var item = bagObj.bagItems[i];
      var obj = {
        'name': item.product.shortName,
        'id': item.product.productShortCode,
        'price': item.option.optionPrice.toFixed(2),
        'category': item.product.series.type,
        'variant': item.optionID,
        'quantity': item.qty
      };
      // console.log(obj);
      gaeProducts.push(obj);
    }

    window.dataLayer.push({
      'event': 'view-bag-page',
      'page-title': pageObj.title,
      'page-path-name': pageObj.pathName,
      'currency-code': currencyCode,
      'bag-item-qty': bagObj.totalQty,
      'bag-sub-total-price': bagObj.subTotalPrice.toFixed(2),
      'bag-sub-total-price-saved': bagObj.subTotalPriceSaved.toFixed(2),
      'bag-delivery-fee': bagObj.deliveryFee.toFixed(2),
      'bag-total-price': bagObj.totalPrice.toFixed(2),
      'ecommerce': {
        'currencyCode': currencyCode,
        'checkout': {
          'actionField': {'step': 1}, //setup in GAE to be View Bag
          'products': gaeProducts
        }
      }
    });
  }
  ,
  //
  // GAE/HEAP: 2 Checkout
  //
  trackCheckout(bagObj, currencyCode) {
    var pageObj = getPageObject();

    // GAE Product Objects
    var gaeProducts = [];

    // FB Content IDs
    var fbContentIDs = '';

    // console.log(bagObj);
    for(var i=0; i<bagObj.bagItems.length; i++) {
      var item = bagObj.bagItems[i];
      var obj = {
        'name': item.product.shortName,
        'id': item.product.productShortCode,
        'price': item.option.optionPrice.toFixed(2),
        'category': item.product.series.type,
        'variant': item.optionID,
        'quantity': item.qty
      };
      // console.log(obj);
      gaeProducts.push(obj);

      if (i>0) {
        fbContentIDs += ',';
      }
      fbContentIDs += 'MY-' + item.product.productShortCode.toUpperCase();
    }

    window.dataLayer.push({
      'event': 'checkout',
      'page-title': pageObj.title,
      'page-path-name': pageObj.pathName,
      'currency-code': currencyCode,
      'bag-item-qty': bagObj.totalQty,
      'bag-sub-total-price': bagObj.subTotalPrice.toFixed(2),
      'bag-sub-total-price-saved': bagObj.subTotalPriceSaved.toFixed(2),
      'bag-delivery-fee': bagObj.deliveryFee.toFixed(2),
      'bag-total-price': bagObj.totalPrice.toFixed(2),
      'fb-content-ids': fbContentIDs,
      'ecommerce': {
        'currencyCode': currencyCode,
        'checkout': {
          'actionField': {'step': 2}, //setup in GAE to be View Bag
          'products': gaeProducts
        }
      }
    });
  }
  ,
  // GAE/HEAP: 3 Add delivery info
  //
  trackAddDeliveryInfo(bagObj, deliveryObj, currencyCode) {
    var pageObj = getPageObject();

    // GAE Product Objects
    var gaeProducts = [];
    // console.log(bagObj);
    for(var i=0; i<bagObj.bagItems.length; i++) {
      var item = bagObj.bagItems[i];
      var obj = {
        'name': item.product.shortName,
        'id': item.product.productShortCode,
        'price': item.option.optionPrice.toFixed(2),
        'category': item.product.series.type,
        'variant': item.optionID,
        'quantity': item.qty
      };
      // console.log(obj);
      gaeProducts.push(obj);
    }

    window.dataLayer.push({
      'event': 'add-delivery-info',
      'page-title': pageObj.title,
      'page-path-name': pageObj.pathName,
      'currency-code': currencyCode,
      'bag-item-qty': bagObj.totalQty,
      'bag-sub-total-price': bagObj.subTotalPrice.toFixed(2),
      'bag-sub-total-price-saved': bagObj.subTotalPriceSaved.toFixed(2),
      'bag-delivery-fee': bagObj.deliveryFee.toFixed(2),
      'bag-total-price': bagObj.totalPrice.toFixed(2),
      'delivery-country': 'Singapore',
      'delivery-city': 'Singapore',
      'delivery-postal-code': deliveryObj.address.postalCode,
      'delivery-address': deliveryObj.address.line1,
      'delivery-address-2': deliveryObj.address.line2,
      'delivery-contact-name': deliveryObj.contact.name,
      'delivery-contact-email': deliveryObj.contact.email,
      'delivery-contact-mobile': deliveryObj.contact.mobile,
      'ecommerce': {
        'currencyCode': currencyCode,
        'checkout': {
          'actionField': {'step': 3}, //setup in GAE to be View Bag
          'products': gaeProducts
        }
      }
    });
  }
  ,
  // //
  // // GAE/HEAP: 4 Place Order
  // //
  trackPlaceOrder(bagObj, deliveryObj, orderObj, currencyCode) {
    var pageObj = getPageObject();

    // GAE Product Objects
    var gaeProducts = [];
    // console.log(bagObj);
    for(var i=0; i<bagObj.bagItems.length; i++) {
      var item = bagObj.bagItems[i];
      var obj = {
        'name': item.product.shortName,
        'id': item.product.productShortCode,
        'price': item.option.optionPrice.toFixed(2),
        'category': item.product.series.type,
        'variant': item.optionID,
        'quantity': item.qty
      };
      // console.log(obj);
      gaeProducts.push(obj);
    }

    window.dataLayer.push({
      'event': 'place-order',
      'page-title': pageObj.title,
      'page-path-name': pageObj.pathName,
      'currency-code': currencyCode,
      'bag-item-qty': bagObj.totalQty,
      'bag-sub-total-price': bagObj.subTotalPrice.toFixed(2),
      'bag-sub-total-price-saved': bagObj.subTotalPriceSaved.toFixed(2),
      'bag-delivery-fee': bagObj.deliveryFee.toFixed(2),
      'bag-total-price': bagObj.totalPrice.toFixed(2),
      'delivery-country': 'Singapore',
      'delivery-city': 'Singapore',
      'delivery-postal-code': deliveryObj.address.postalCode,
      'delivery-address': deliveryObj.address.line1,
      'delivery-address-2': deliveryObj.address.line2,
      'delivery-contact-name': deliveryObj.contact.name,
      'delivery-contact-email': deliveryObj.contact.email,
      'delivery-contact-mobile': deliveryObj.contact.mobile,
      'order-id': orderObj.orderID,
      'invoice-id': orderObj.invoiceID,
      'promo-code': orderObj.promoCode,
      'ecommerce': {
        'currencyCode': currencyCode,
        'checkout': {
          'actionField': {'step': 4}, //setup in GAE to be View Bag
          'products': gaeProducts
        }
      }
    });
  }
  ,
  //
  // GAE/HEAP: 5 - Made payment
  //
  trackMadePayment(bagObj, deliveryObj, orderObj, currencyCode) {
    var pageObj = getPageObject();

    // GAE Product Objects
    var gaeProducts = [];

    // FB Content IDs
    var fbContentIDs = '';

    // console.log(bagObj);
    for(var i=0; i<bagObj.bagItems.length; i++) {
      var item = bagObj.bagItems[i];
      var obj = {
        'name': item.product.shortName,
        'id': item.product.productShortCode,
        'price': item.option.optionPrice.toFixed(2),
        'category': item.product.series.type,
        'variant': item.optionID,
        'quantity': item.qty
      };
      // console.log(obj);
      gaeProducts.push(obj);

      if (i>0) {
        fbContentIDs += ',';
      }
      fbContentIDs += item.product.productShortCode.toUpperCase();
    }

    window.dataLayer.push({
      'event': 'made-payment',
      'page-title': pageObj.title,
      'page-path-name': pageObj.pathName,
      'currency-code': currencyCode,
      'bag-item-qty': bagObj.totalQty,
      'bag-sub-total-price': bagObj.subTotalPrice.toFixed(2),
      'bag-sub-total-price-saved': bagObj.subTotalPriceSaved.toFixed(2),
      'bag-delivery-fee': bagObj.deliveryFee.toFixed(2),
      'bag-total-price': bagObj.totalPrice.toFixed(2),
      'delivery-country': 'Singapore',
      'delivery-city': 'Singapore',
      'delivery-postal-code': deliveryObj.address.postalCode,
      'delivery-address': deliveryObj.address.line1,
      'delivery-address-2': deliveryObj.address.line2,
      'delivery-contact-name': deliveryObj.contact.name,
      'delivery-contact-email': deliveryObj.contact.email,
      'delivery-contact-mobile': deliveryObj.contact.mobile,
      'order-id': orderObj.orderID,
      'invoice-id': orderObj.invoiceID,
      'promo-code': orderObj.promoCode,
      'fb-content-ids': fbContentIDs,
      'ecommerce': {
        'currencyCode': currencyCode,
        'purchase': {
          'actionField': {
            'id': orderObj.invoiceID,
            'revenue': bagObj.totalPrice.toFixed(2),
            'tax': '0',
            'shipping': bagObj.deliveryFee.toFixed(2),
            'coupon': orderObj.promoCode
          },
          'products': gaeProducts
        }
      }
    });
  }
  
}
