import $ from 'jquery';
import Cookies from 'js-cookie';
import UtilCampaignDisplay from 'doki-js/util-campaign--display';
import UtilCampaignOption from 'doki-js/util-campaign--option';
import UtilCampaignSubTotal from 'doki-js/util-campaign--sub-total';
import UtilCampaignBoxCount from 'doki-js/util-campaign--box-count';
import UtilCampaignFreeGift from 'doki-js/util-campaign--free-gift';


export default {

  getPromoCodeInCookies() {
    return getPromoCodeInCookies();
  },

  setPromoCodeInCookies(newCode=null) { //null means remove code

    if(newCode == null)
      Cookies.remove('promoCode');
    else {
      newCode = newCode.toString().toUpperCase()
      Cookies.set('promoCode', newCode);
    }
  },

  getDisplaysForCampaignPage(jsonCampaigns) {
    
    var obj = {campaignDisplay: {}};
    obj.campaignDisplay.campaignPage = UtilCampaignDisplay.getDisplaysForCampaignPage(jsonCampaigns, getPromoCodeInCookies());
    return obj;
  },

  getDisplaysForProductListItem(productShortCode, jsonCampaigns, timestamp) {
    
    var obj = {campaignDisplay: {}};
    obj.campaignDisplay.productListItem =  UtilCampaignDisplay.getDisplaysForProductListItem(productShortCode, jsonCampaigns, timestamp);
    return obj;
  },

  getDisplaysForPurchaseOption(productShortCode, jsonCampaigns, timestamp) {
    
    var obj = {campaignDisplay: {}};
    obj.campaignDisplay.purchaseOption = UtilCampaignDisplay.getDisplaysForPurchaseOption(productShortCode, jsonCampaigns, timestamp);
    
    return obj;
  },

  getDisplaysForProductPage(productShortCode, jsonCampaigns, timestamp) {
    
    var obj = {campaignDisplay: {}};
    obj.campaignDisplay.campaignPage = UtilCampaignDisplay.getDisplaysForCampaignPage(jsonCampaigns, getPromoCodeInCookies());
    obj.campaignDisplay.purchaseOption = UtilCampaignDisplay.getDisplaysForPurchaseOption(productShortCode, jsonCampaigns, timestamp);
    obj.campaignDisplay.productPage = UtilCampaignDisplay.getDisplaysForProductPage(productShortCode, jsonCampaigns, timestamp);

    return obj;
  },

  getDisplaysForBagPage(bagObj, jsonCampaigns, timestamp) {
    
    var obj = {campaignDisplay: {}};
    obj.campaignDisplay.campaignPage = UtilCampaignDisplay.getDisplaysForCampaignPage(jsonCampaigns, getPromoCodeInCookies());
    obj.campaignDisplay.bagPage = UtilCampaignDisplay.getDisplaysForBagPage(bagObj, jsonCampaigns, timestamp);

    return obj;
  },

  getDisplaysForSummaryPage(bagObj, jsonCampaigns, timestamp) {
    
    var obj = {campaignDisplay: {}};
    obj.campaignDisplay.campaignPage = UtilCampaignDisplay.getDisplaysForCampaignPage(jsonCampaigns, getPromoCodeInCookies());
    obj.campaignDisplay.summaryPage = UtilCampaignDisplay.getDisplaysForSummaryPage(bagObj, jsonCampaigns, timestamp);

    return obj;
  },

  // Called from:
  //  -UtilCheckout.getBagObject
  getDiscountedOptionObject(jsonCampaigns, jsonProducts, jsonBagItems, objOption, initialSubTotalPrice, timestamp, promoCode, isServerPromoCode, isValidServerPromoCode) {
    return UtilCampaignOption.getDiscountedOptionObject(jsonCampaigns, jsonProducts, jsonBagItems, objOption, initialSubTotalPrice, timestamp, promoCode, isServerPromoCode, isValidServerPromoCode);
  },

  getDiscountedSubTotalObject(jsonCampaigns, jsonProducts, jsonBagItems, initialSubTotalPrice, timestamp, promoCode, isServerPromoCode, isValidServerPromoCode) {
    return UtilCampaignSubTotal.getDiscountedSubTotalObject(jsonCampaigns, jsonProducts, jsonBagItems, initialSubTotalPrice, timestamp, promoCode, isServerPromoCode, isValidServerPromoCode);
  },

  getDiscountedBoxCountObject(jsonCampaigns, boxCount, timestamp, promoCode, isServerPromoCode, isValidServerPromoCode) {
    return UtilCampaignBoxCount.getDiscountedBoxCountObject(jsonCampaigns, boxCount, timestamp, promoCode, isServerPromoCode, isValidServerPromoCode);
  },

  getFreeGiftObject(jsonBagItems, jsonProducts, jsonCampaigns, timestamp, promoCode, initialSubTotalPrice) {
    return UtilCampaignFreeGift.getFreeGiftObject(jsonBagItems, jsonProducts, jsonCampaigns, timestamp, promoCode, initialSubTotalPrice);
  }
}

function getPromoCodeInCookies() {
    
  var code = null;
  
  if(Cookies.get('promoCode') !== undefined) {
    code = Cookies.get('promoCode');
    code = code.toString().toUpperCase()
  }
  
    return code;
}