import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _27589d30 = () => interopDefault(import('../pages/about-us/index.vue' /* webpackChunkName: "pages/about-us/index" */))
const _2f787c0c = () => interopDefault(import('../pages/bag/index.vue' /* webpackChunkName: "pages/bag/index" */))
const _dcbadc86 = () => interopDefault(import('../pages/contact-us/index.vue' /* webpackChunkName: "pages/contact-us/index" */))
const _5daad7b6 = () => interopDefault(import('../pages/data.js' /* webpackChunkName: "pages/data" */))
const _3a8cee74 = () => interopDefault(import('../pages/delivery/index.vue' /* webpackChunkName: "pages/delivery/index" */))
const _52b33bcc = () => interopDefault(import('../pages/error/index.vue' /* webpackChunkName: "pages/error/index" */))
const _5074b466 = () => interopDefault(import('../pages/order-confirmation/index.vue' /* webpackChunkName: "pages/order-confirmation/index" */))
const _78d94a2e = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _74cede96 = () => interopDefault(import('../pages/script.js' /* webpackChunkName: "pages/script" */))
const _20ec9daa = () => interopDefault(import('../pages/summary/index.vue' /* webpackChunkName: "pages/summary/index" */))
const _359c2653 = () => interopDefault(import('../pages/about-us/data.js' /* webpackChunkName: "pages/about-us/data" */))
const _6670ac12 = () => interopDefault(import('../pages/about-us/script.js' /* webpackChunkName: "pages/about-us/script" */))
const _769e0c2f = () => interopDefault(import('../pages/bag/data.js' /* webpackChunkName: "pages/bag/data" */))
const _6e908aee = () => interopDefault(import('../pages/bag/script.js' /* webpackChunkName: "pages/bag/script" */))
const _5b03da20 = () => interopDefault(import('../pages/contact-us/data.js' /* webpackChunkName: "pages/contact-us/data" */))
const _5e8abec2 = () => interopDefault(import('../pages/contact-us/script.js' /* webpackChunkName: "pages/contact-us/script" */))
const _524eff69 = () => interopDefault(import('../pages/delivery/data.js' /* webpackChunkName: "pages/delivery/data" */))
const _21d197a8 = () => interopDefault(import('../pages/delivery/script.js' /* webpackChunkName: "pages/delivery/script" */))
const _d5f48822 = () => interopDefault(import('../pages/error/data.js' /* webpackChunkName: "pages/error/data" */))
const _dc696aa4 = () => interopDefault(import('../pages/error/script.js' /* webpackChunkName: "pages/error/script" */))
const _6e209309 = () => interopDefault(import('../pages/order-confirmation/data.js' /* webpackChunkName: "pages/order-confirmation/data" */))
const _e0e67970 = () => interopDefault(import('../pages/order-confirmation/script.js' /* webpackChunkName: "pages/order-confirmation/script" */))
const _5aa60368 = () => interopDefault(import('../pages/privacy-policy/data.js' /* webpackChunkName: "pages/privacy-policy/data" */))
const _02ab69cb = () => interopDefault(import('../pages/privacy-policy/script.js' /* webpackChunkName: "pages/privacy-policy/script" */))
const _02570f55 = () => interopDefault(import('../pages/shop/booster-superfood/index.vue' /* webpackChunkName: "pages/shop/booster-superfood/index" */))
const _7db05500 = () => interopDefault(import('../pages/shop/cadence-energising/index.vue' /* webpackChunkName: "pages/shop/cadence-energising/index" */))
const _705020ad = () => interopDefault(import('../pages/shop/gift/index.vue' /* webpackChunkName: "pages/shop/gift/index" */))
const _084707b4 = () => interopDefault(import('../pages/shop/glow-collagen/index.vue' /* webpackChunkName: "pages/shop/glow-collagen/index" */))
const _821bd45a = () => interopDefault(import('../pages/shop/glow-plus-collagen-anti-photoaging/index.vue' /* webpackChunkName: "pages/shop/glow-plus-collagen-anti-photoaging/index" */))
const _00954a1e = () => interopDefault(import('../pages/shop/hype-fitness/index.vue' /* webpackChunkName: "pages/shop/hype-fitness/index" */))
const _7b4eddfe = () => interopDefault(import('../pages/shop/lean-up-calorie-burning/index.vue' /* webpackChunkName: "pages/shop/lean-up-calorie-burning/index" */))
const _0d3715a8 = () => interopDefault(import('../pages/shop/product/index.vue' /* webpackChunkName: "pages/shop/product/index" */))
const _396c0566 = () => interopDefault(import('../pages/summary/data.js' /* webpackChunkName: "pages/summary/data" */))
const _6004ac8c = () => interopDefault(import('../pages/summary/script.js' /* webpackChunkName: "pages/summary/script" */))
const _6d2a4490 = () => interopDefault(import('../pages/shop/booster-superfood/data.js' /* webpackChunkName: "pages/shop/booster-superfood/data" */))
const _416f1e37 = () => interopDefault(import('../pages/shop/booster-superfood/script.js' /* webpackChunkName: "pages/shop/booster-superfood/script" */))
const _41c0dbba = () => interopDefault(import('../pages/shop/cadence-energising/data.js' /* webpackChunkName: "pages/shop/cadence-energising/data" */))
const _866f383c = () => interopDefault(import('../pages/shop/cadence-energising/script.js' /* webpackChunkName: "pages/shop/cadence-energising/script" */))
const _10a86510 = () => interopDefault(import('../pages/shop/gift/data.js' /* webpackChunkName: "pages/shop/gift/data" */))
const _a12fa0e2 = () => interopDefault(import('../pages/shop/gift/script.js' /* webpackChunkName: "pages/shop/gift/script" */))
const _65293c52 = () => interopDefault(import('../pages/shop/glow-collagen/data.js' /* webpackChunkName: "pages/shop/glow-collagen/data" */))
const _475f1696 = () => interopDefault(import('../pages/shop/glow-collagen/script.js' /* webpackChunkName: "pages/shop/glow-collagen/script" */))
const _6d716bb6 = () => interopDefault(import('../pages/shop/glow-plus-collagen-anti-photoaging/data.js' /* webpackChunkName: "pages/shop/glow-plus-collagen-anti-photoaging/data" */))
const _03ebb696 = () => interopDefault(import('../pages/shop/glow-plus-collagen-anti-photoaging/script.js' /* webpackChunkName: "pages/shop/glow-plus-collagen-anti-photoaging/script" */))
const _425b36c1 = () => interopDefault(import('../pages/shop/hype-fitness/data.js' /* webpackChunkName: "pages/shop/hype-fitness/data" */))
const _3fad5900 = () => interopDefault(import('../pages/shop/hype-fitness/script.js' /* webpackChunkName: "pages/shop/hype-fitness/script" */))
const _34f8da64 = () => interopDefault(import('../pages/shop/lean-up-calorie-burning/data.js' /* webpackChunkName: "pages/shop/lean-up-calorie-burning/data" */))
const _01709fe3 = () => interopDefault(import('../pages/shop/lean-up-calorie-burning/script.js' /* webpackChunkName: "pages/shop/lean-up-calorie-burning/script" */))
const _372e5ccb = () => interopDefault(import('../pages/shop/product/data.js' /* webpackChunkName: "pages/shop/product/data" */))
const _4c4f248a = () => interopDefault(import('../pages/shop/product/script.js' /* webpackChunkName: "pages/shop/product/script" */))
const _4dbe77c0 = () => interopDefault(import('../pages/shop/booster-superfood/_id/index.vue' /* webpackChunkName: "pages/shop/booster-superfood/_id/index" */))
const _4a56efeb = () => interopDefault(import('../pages/shop/cadence-energising/_id/index.vue' /* webpackChunkName: "pages/shop/cadence-energising/_id/index" */))
const _0b9f1d18 = () => interopDefault(import('../pages/shop/gift/_id/index.vue' /* webpackChunkName: "pages/shop/gift/_id/index" */))
const _702d289f = () => interopDefault(import('../pages/shop/glow-collagen/_id/index.vue' /* webpackChunkName: "pages/shop/glow-collagen/_id/index" */))
const _bf53e184 = () => interopDefault(import('../pages/shop/glow-plus-collagen-anti-photoaging/_id/index.vue' /* webpackChunkName: "pages/shop/glow-plus-collagen-anti-photoaging/_id/index" */))
const _39602609 = () => interopDefault(import('../pages/shop/hype-fitness/_id/index.vue' /* webpackChunkName: "pages/shop/hype-fitness/_id/index" */))
const _488f436c = () => interopDefault(import('../pages/shop/lean-up-calorie-burning/_id/index.vue' /* webpackChunkName: "pages/shop/lean-up-calorie-burning/_id/index" */))
const _1dca5132 = () => interopDefault(import('../pages/order-details/_id/index.vue' /* webpackChunkName: "pages/order-details/_id/index" */))
const _20ea58d6 = () => interopDefault(import('../pages/summary/_id/index.vue' /* webpackChunkName: "pages/summary/_id/index" */))
const _5d8ef2d5 = () => interopDefault(import('../pages/order-details/_id/data.js' /* webpackChunkName: "pages/order-details/_id/data" */))
const _5ce26014 = () => interopDefault(import('../pages/order-details/_id/script.js' /* webpackChunkName: "pages/order-details/_id/script" */))
const _f2fefc5a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _27589d30,
    name: "about-us"
  }, {
    path: "/bag",
    component: _2f787c0c,
    name: "bag"
  }, {
    path: "/contact-us",
    component: _dcbadc86,
    name: "contact-us"
  }, {
    path: "/data",
    component: _5daad7b6,
    name: "data"
  }, {
    path: "/delivery",
    component: _3a8cee74,
    name: "delivery"
  }, {
    path: "/error",
    component: _52b33bcc,
    name: "error"
  }, {
    path: "/order-confirmation",
    component: _5074b466,
    name: "order-confirmation"
  }, {
    path: "/privacy-policy",
    component: _78d94a2e,
    name: "privacy-policy"
  }, {
    path: "/script",
    component: _74cede96,
    name: "script"
  }, {
    path: "/summary",
    component: _20ec9daa,
    name: "summary"
  }, {
    path: "/about-us/data",
    component: _359c2653,
    name: "about-us-data"
  }, {
    path: "/about-us/script",
    component: _6670ac12,
    name: "about-us-script"
  }, {
    path: "/bag/data",
    component: _769e0c2f,
    name: "bag-data"
  }, {
    path: "/bag/script",
    component: _6e908aee,
    name: "bag-script"
  }, {
    path: "/contact-us/data",
    component: _5b03da20,
    name: "contact-us-data"
  }, {
    path: "/contact-us/script",
    component: _5e8abec2,
    name: "contact-us-script"
  }, {
    path: "/delivery/data",
    component: _524eff69,
    name: "delivery-data"
  }, {
    path: "/delivery/script",
    component: _21d197a8,
    name: "delivery-script"
  }, {
    path: "/error/data",
    component: _d5f48822,
    name: "error-data"
  }, {
    path: "/error/script",
    component: _dc696aa4,
    name: "error-script"
  }, {
    path: "/order-confirmation/data",
    component: _6e209309,
    name: "order-confirmation-data"
  }, {
    path: "/order-confirmation/script",
    component: _e0e67970,
    name: "order-confirmation-script"
  }, {
    path: "/privacy-policy/data",
    component: _5aa60368,
    name: "privacy-policy-data"
  }, {
    path: "/privacy-policy/script",
    component: _02ab69cb,
    name: "privacy-policy-script"
  }, {
    path: "/shop/booster-superfood",
    component: _02570f55,
    name: "shop-booster-superfood"
  }, {
    path: "/shop/cadence-energising",
    component: _7db05500,
    name: "shop-cadence-energising"
  }, {
    path: "/shop/gift",
    component: _705020ad,
    name: "shop-gift"
  }, {
    path: "/shop/glow-collagen",
    component: _084707b4,
    name: "shop-glow-collagen"
  }, {
    path: "/shop/glow-plus-collagen-anti-photoaging",
    component: _821bd45a,
    name: "shop-glow-plus-collagen-anti-photoaging"
  }, {
    path: "/shop/hype-fitness",
    component: _00954a1e,
    name: "shop-hype-fitness"
  }, {
    path: "/shop/lean-up-calorie-burning",
    component: _7b4eddfe,
    name: "shop-lean-up-calorie-burning"
  }, {
    path: "/shop/product",
    component: _0d3715a8,
    name: "shop-product"
  }, {
    path: "/summary/data",
    component: _396c0566,
    name: "summary-data"
  }, {
    path: "/summary/script",
    component: _6004ac8c,
    name: "summary-script"
  }, {
    path: "/shop/booster-superfood/data",
    component: _6d2a4490,
    name: "shop-booster-superfood-data"
  }, {
    path: "/shop/booster-superfood/script",
    component: _416f1e37,
    name: "shop-booster-superfood-script"
  }, {
    path: "/shop/cadence-energising/data",
    component: _41c0dbba,
    name: "shop-cadence-energising-data"
  }, {
    path: "/shop/cadence-energising/script",
    component: _866f383c,
    name: "shop-cadence-energising-script"
  }, {
    path: "/shop/gift/data",
    component: _10a86510,
    name: "shop-gift-data"
  }, {
    path: "/shop/gift/script",
    component: _a12fa0e2,
    name: "shop-gift-script"
  }, {
    path: "/shop/glow-collagen/data",
    component: _65293c52,
    name: "shop-glow-collagen-data"
  }, {
    path: "/shop/glow-collagen/script",
    component: _475f1696,
    name: "shop-glow-collagen-script"
  }, {
    path: "/shop/glow-plus-collagen-anti-photoaging/data",
    component: _6d716bb6,
    name: "shop-glow-plus-collagen-anti-photoaging-data"
  }, {
    path: "/shop/glow-plus-collagen-anti-photoaging/script",
    component: _03ebb696,
    name: "shop-glow-plus-collagen-anti-photoaging-script"
  }, {
    path: "/shop/hype-fitness/data",
    component: _425b36c1,
    name: "shop-hype-fitness-data"
  }, {
    path: "/shop/hype-fitness/script",
    component: _3fad5900,
    name: "shop-hype-fitness-script"
  }, {
    path: "/shop/lean-up-calorie-burning/data",
    component: _34f8da64,
    name: "shop-lean-up-calorie-burning-data"
  }, {
    path: "/shop/lean-up-calorie-burning/script",
    component: _01709fe3,
    name: "shop-lean-up-calorie-burning-script"
  }, {
    path: "/shop/product/data",
    component: _372e5ccb,
    name: "shop-product-data"
  }, {
    path: "/shop/product/script",
    component: _4c4f248a,
    name: "shop-product-script"
  }, {
    path: "/shop/booster-superfood/:id?",
    component: _4dbe77c0,
    name: "shop-booster-superfood-id"
  }, {
    path: "/shop/cadence-energising/:id?",
    component: _4a56efeb,
    name: "shop-cadence-energising-id"
  }, {
    path: "/shop/gift/:id",
    component: _0b9f1d18,
    name: "shop-gift-id"
  }, {
    path: "/shop/glow-collagen/:id?",
    component: _702d289f,
    name: "shop-glow-collagen-id"
  }, {
    path: "/shop/glow-plus-collagen-anti-photoaging/:id?",
    component: _bf53e184,
    name: "shop-glow-plus-collagen-anti-photoaging-id"
  }, {
    path: "/shop/hype-fitness/:id?",
    component: _39602609,
    name: "shop-hype-fitness-id"
  }, {
    path: "/shop/lean-up-calorie-burning/:id?",
    component: _488f436c,
    name: "shop-lean-up-calorie-burning-id"
  }, {
    path: "/order-details/:id?",
    component: _1dca5132,
    name: "order-details-id"
  }, {
    path: "/summary/:id",
    component: _20ea58d6,
    name: "summary-id"
  }, {
    path: "/order-details/:id?/data",
    component: _5d8ef2d5,
    name: "order-details-id-data"
  }, {
    path: "/order-details/:id?/script",
    component: _5ce26014,
    name: "order-details-id-script"
  }, {
    path: "/",
    component: _f2fefc5a,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
