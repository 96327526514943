import $ from 'jquery';

// Reward types
const REWARD_TYPE__FREE_GIFT = 'free-gift';

// Condition types
const CONDITION_TYPE__DURATION = 'duration';
const CONDITION_TYPE__PROMO_CODE = 'promo-code';
const CONDITION_TYPE__MIN_GLOW_UNIT_PURCHASE = 'min-glow-unit-purchase';
const CONDITION_TYPE__MIN_SUB_TOTAL = 'min-sub-total';
const CONDITION_TYPE__WITH_OPTION_PURCHASE = 'with-option-purchase';


export default {

  getFreeGiftObject(jsonBagItems, jsonProducts, jsonCampaigns, timestamp, promoCode, initialSubTotalPrice) {

    for(var i=0; i<jsonCampaigns.length; i++) {

      if (jsonCampaigns[i].target == 'free-gift') {

        console.log('  +++++++++++++++');
        console.log('  CAMPAIGN ' + (i+1) + ': ' + jsonCampaigns[i].description);

        if (jsonCampaigns[i].reward !== undefined) { //some capaigns have not reward but for display purpose only
          var objReward = jsonCampaigns[i].reward;

          // Check if campaign conditions are valid
          console.log('    Validating conditions');
          var isValidCampaign = isValidFreeGiftConditions(jsonCampaigns[i].conditions, timestamp, promoCode, jsonBagItems, jsonProducts, initialSubTotalPrice);
          console.log('    Result: isValidCampaign = '+ isValidCampaign);

          if(isValidCampaign) {

            console.log('  Processing reward');

            switch(objReward.type) {

              // Reward is free gift
              case REWARD_TYPE__FREE_GIFT:
                return getFreeGiftObject__REWARD_TYPE__FREE_GIFT(objReward, jsonBagItems, jsonProducts, initialSubTotalPrice);
                break;
            }
          }
        }
      }
    }
    return null;
  }
}


function isValidFreeGiftConditions(jsonConditions, timestamp, promoCode, jsonBagItems, jsonProducts, initialSubTotalPrice) {

  // Check timestamp condition (Mandatory)
  var isWithinDuration = false;
  for(var i=0; i<jsonConditions.length; i++) {

    if(jsonConditions[i].type == CONDITION_TYPE__DURATION) {

      if( timestamp > jsonConditions[i].values.timestampFrom &&
          timestamp < jsonConditions[i].values.timestampTo) {
        isWithinDuration = true;
      }
    }
  }

  // Check promo code condition (optional)
  if (!isWithinDuration) {
    console.log('    + Not within duration');
    return false
  }
  else {
    console.log('    + Within duration');
    var hasPromoCodeCondition = false;

    var hasInvalidCondition = false;

    for(var i=0; i<jsonConditions.length; i++) {

      if(jsonConditions[i].type == CONDITION_TYPE__PROMO_CODE) {
        console.log('    + Condition type = ' + CONDITION_TYPE__PROMO_CODE);
        hasPromoCodeCondition = true;

        if (promoCode !== null && promoCode !== undefined && promoCode !== ''
            && jsonConditions[i].values.code != undefined
            && jsonConditions[i].values.code.toString().toUpperCase() == promoCode.toString().toUpperCase()) {

          console.log('    + Valid promo code');
        }
        else {
          hasInvalidCondition = true;
          console.log('    + Invalid promo code');
        }
      }

      else if ( jsonConditions[i].type == CONDITION_TYPE__MIN_SUB_TOTAL ) {
        console.log('    + Condition type = ' + CONDITION_TYPE__MIN_SUB_TOTAL);

        // check subtotal amount
        if (jsonConditions[i].values.minSubTotal == undefined ||
            initialSubTotalPrice < parseInt(jsonConditions[i].values.minSubTotal) ){

            hasInvalidCondition = true;
            console.log(initialSubTotalPrice + " | " + parseInt(jsonConditions[i].values.minSubTotal));
            console.log('    + Subtotal is insufficient');
        }
        else {
          console.log('    + Subtotal is sufficient');
        }
      }

      else if ( jsonConditions[i].type == CONDITION_TYPE__WITH_OPTION_PURCHASE ) {
        console.log('    + Condition type = ' + CONDITION_TYPE__WITH_OPTION_PURCHASE);

        var optionCode = jsonConditions[i].values.option;
        var minUnitQty = parseInt(jsonConditions[i].values.minUnitQty);
        var unitFoundCount = 0;

        for (var j=0; j<jsonBagItems.length; j++) {

          if (jsonBagItems[j].optionID == optionCode) {
            unitFoundCount += parseInt(jsonBagItems[j].qty);
          }
        }

        if (unitFoundCount < minUnitQty) {
          hasInvalidCondition = true;
          console.log('    + Insufficient qty of option purchased');
        }
        else {
          console.log('    + Sufficient qty of option purchased');
        }
      }

      else if ( jsonConditions[i].type == CONDITION_TYPE__MIN_GLOW_UNIT_PURCHASE ) {
        console.log('    + Condition type = ' + CONDITION_TYPE__MIN_GLOW_UNIT_PURCHASE);

        if (jsonBagItems == undefined || jsonBagItems == null ||
            jsonProducts == undefined || jsonProducts == null) {

          hasInvalidCondition = true;
          console.log('    + Json undefined');
        }

        if (!hasInvalidCondition) {
          // count glow boxes
          var glowUnitCount = countGlowUnit(jsonBagItems, jsonProducts);
          if (glowUnitCount < jsonConditions[i].values.minUnitQty)
          {
            hasInvalidCondition = true;
            console.log('    + Min Unity Qty not met');
          }
          else {
            console.log('    + Min Unity Qty met');
          }
        }
      }
      else {

        if ( jsonConditions[i].type != 'duration') //first condiyion is always duration which already validated
          return false;
      }

      if (hasInvalidCondition) {
        return false;
      }
    }
  }
  return true;

}

function countGlowUnit(jsonBagItems, jsonProducts) {

  var glowUnitCount = 0;

  for(var j=0; j<jsonBagItems.length; j++) {

    var optionID = jsonBagItems[j].optionID;
    var shortCode = getOptionProductShortCode(optionID);
    var objProduct = jsonProducts[shortCode];
    var qty = parseInt(jsonBagItems[j].qty);

    if (objProduct != undefined) {
      if (shortCode == 'bbeg') {
        glowUnitCount += parseInt(qty * 2);
      }
      else if (shortCode == 'bgpg') {
        glowUnitCount += parseInt(qty * 3);
      }
      else if (objProduct.series.name == 'Glow') {
        glowUnitCount += parseInt(qty * getOptionProductUnitQty(optionID));
      }
    }
  }

  return glowUnitCount;
}


function getFreeGiftObject__REWARD_TYPE__FREE_GIFT(objReward, jsonBagItems, jsonProducts, initialSubTotalPrice) {

  var freeGiftObject = initFreeGiftObject();

  if (jsonBagItems == undefined || jsonBagItems == null ||
      jsonProducts == undefined || jsonProducts == null) {
    return null;
  }

  var objFreeItem = objReward.items[0];
  var freeUnitQty = parseInt(objFreeItem.values.freeUnitQty);
  var maxUnitQty = parseInt(objFreeItem.values.terms.maxUnitQty);

  var isRecursive = objFreeItem.values.terms.recursive;

  var totalFreeUnitQty = 0;

  if (objFreeItem.values.terms.minSubTotal !== undefined) { //minSubTotal

    if (initialSubTotalPrice > Number(objFreeItem.values.terms.minSubTotal)) {

      var minSubTotal = Number(objFreeItem.values.terms.minSubTotal);

      if (isRecursive==true) {

        var recursiveUnitQty = parseInt(objFreeItem.values.terms.recursiveUnitQty);
        // console.log('recursiveUnitQty: ' + recursiveUnitQty);
        var recursiveSubTotal = Number(objFreeItem.values.terms.recursiveSubTotal);
        // console.log('recursiveSubTotal: ' + recursiveSubTotal);

        totalFreeUnitQty = parseInt(Math.floor(
                              (initialSubTotalPrice - minSubTotal)
                              /
                              recursiveSubTotal
                            ) * recursiveUnitQty) + freeUnitQty;
        // console.log('initialSubTotalPrice: ' + initialSubTotalPrice);
        // console.log('minSubTotal: ' + minSubTotal);
        // console.log('freeUnitQty: ' + freeUnitQty);
        // console.log('totalFreeUnitQty: ' + totalFreeUnitQty);
      }
      else {
        totalFreeUnitQty = freeUnitQty;
      }
    }
  }
  else { //minUnitPurchase
    // count glow boxes
    var glowUnitCount = countGlowUnit(jsonBagItems, jsonProducts);
    // console.log('glowUnitCount: ' + glowUnitCount);

    if (isRecursive==true) {

      var recursiveUnitQty = parseInt(objFreeItem.values.terms.recursiveUnitQty);
      var recursivePurchaseUnitQty = parseInt(objFreeItem.values.terms.recursivePurchaseUnitQty);

      totalFreeUnitQty = parseInt(Math.floor(glowUnitCount/recursivePurchaseUnitQty) * freeUnitQty);
    }
    else {
      totalFreeUnitQty = freeUnitQty;
    }
  }

  totalFreeUnitQty = Math.min(totalFreeUnitQty, maxUnitQty);
  // console.log('totalFreeUnitQty: ' + totalFreeUnitQty);

  freeGiftObject.description = objFreeItem.values.text.replace("[FREE_QTY]", totalFreeUnitQty);
  // console.log('description: ' + freeGiftObject.description);

  return freeGiftObject;
}


function initFreeGiftObject() {
  var freeGiftObject = {
    description: ''
  }

  return freeGiftObject;
}

function getOptionProductShortCode(optionID) {

  var ids = optionID.split('-');

  if (ids[0] == 'op') {
    return ids[1];
  }
  return null;
}

function getOptionProductUnitQty(optionID) {

  var ids = optionID.split('-');

  if (ids[0] == 'op') {
    return parseInt(ids[2].slice(0,-1));
  }
  return null;
}
