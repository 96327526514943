export const FETCH_SERVER_TIME = 'fetchServerTime';
export const SET_SERVER_TIME = 'setServerTime';
export const STORE_SERVER_TIME_TO_LOCAL_STORAGE = 'storeServerTimeToLocalStorage';
export const FETCH_SERVER_TIME_FROM_LOCAL_STORAGE = 'fetchServerTimeFromLocalStorage';
export const TIME = 'time';

export const state = () => ({
  serverTime: 0,
  serverTimeFetch: 0,
  serverTimeFetched: 0,
});

export const mutations = {
  [SET_SERVER_TIME](state, { serverTime, serverTimeFetch, serverTimeFetched }) {
    state.serverTime = serverTime;
    state.serverTimeFetch = serverTimeFetch;
    state.serverTimeFetched = serverTimeFetched;
  },
};

export const actions = {
  [FETCH_SERVER_TIME_FROM_LOCAL_STORAGE]() {
    const time = localStorage.getItem(TIME);
    return time && time !== 'null' && time !== 'undefined'
      ? JSON.parse(localStorage.getItem(TIME))
      : null;
  },
  async [STORE_SERVER_TIME_TO_LOCAL_STORAGE](context, payload) {
    localStorage.setItem(TIME, JSON.stringify(payload));
  },
  async [FETCH_SERVER_TIME]({ dispatch, state }) {
    const TIME = await dispatch(FETCH_SERVER_TIME_FROM_LOCAL_STORAGE);
    if (!TIME) {
      const serverTimeFetch = Math.floor(Date.now() / 1000);
      const { data } = await this.$axios.get('/api/get-server-time');
      const timestamp = Math.floor(Date.now() / 1000);
      const serverTimeFetched = timestamp;
      const serverTime = data.unixtime ? data.unixtime : timestamp;
      return dispatch(SET_SERVER_TIME, { serverTime, serverTimeFetch, serverTimeFetched });
    }
    return TIME.serverTime;
  },
  async [SET_SERVER_TIME]({ commit, dispatch }, payload) {
    await dispatch(STORE_SERVER_TIME_TO_LOCAL_STORAGE, payload);
    commit(SET_SERVER_TIME, payload);
  },
};

export const getters = {
  getTimeStamp(state) {
    const { serverTime, serverTimeFetch, serverTimeFetched } = state;
    const newTime = serverTime
      + (Math.floor(Date.now() / 1000) - serverTimeFetched)
      + (serverTimeFetched - serverTimeFetch);
    return newTime;
  },
};
