import head from './head.json';
import { getData } from './data';

export default {
  head,
  name: 'ErrorPage',
  layout: 'default/index',
  props: {},
  components: {},
  data() {
    return {
      ...getData(),
    };
  },
  watch: {},
  computed: {},
  methods: {},
  beforeCreated() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
};
