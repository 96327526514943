import { Model } from '@vuex-orm/core';
import pkg from '@/package';

export default class Bag extends Model {
  static entity = 'bags';

  static primaryKey = '$id';

  static fields() {
    return {
      $id: this.attr(null),
      ver: this.string(pkg.version),
      productShortCode: this.string(null),
      optionID: this.string(null),
      qty: this.number(null),
      optionPrice: this.number(null),
      originalOptionPrice: this.number(null),
      discountedOptionPrice: this.number(null),
    };
  }
}
