import _ from 'lodash';

export const SEARCH_ADDRESS_BY_POSTALCODE = 'searchAddressByPostalCode';
export const CREATE_CUSTOMER = 'createCustomer';
export const UPDATE_CUSTOMER = 'updateCustomer';
export const SET_INITIAL_CUSTOMER = 'setInitialCustomer';
export const STORE_CUSTOMER_TO_LOCAL_STORAGE = 'storeCustomerToLocalStorage';
export const FETCH_CUSTOMER_FROM_LOCAL_STORAGE = 'fetchCustomerFromLocalStorage';
export const FETCH_CUSTOMER_FROM_SERVER = 'fetchCustomerFromServer';
export const REMOVE_CUSTOMER = 'removeCustomer';
export const CUSTOMERS = 'customers';
export const CUSTOMER = 'customer';
export const SUBSCRIBE_TO_NEWSLETTER = 'subscribeToNewsletter';

export const state = () => ({});

export const mutations = {};

export const actions = {
  async [SUBSCRIBE_TO_NEWSLETTER](context, payload) {
    const { data } = await this.$axios.post('/api/subscribe-to-newsletter/my', payload);
    return data;
  },
  async [FETCH_CUSTOMER_FROM_SERVER](context, email) {
    const { data } = await this.$axios.get(`/api/get-customer/my/${email}`);
    return data;
  },
  async [FETCH_CUSTOMER_FROM_LOCAL_STORAGE]() {
    const customer = localStorage.getItem(CUSTOMER);
    return customer
      ? JSON.parse(localStorage.getItem(CUSTOMER))
      : null;
  },
  async [SET_INITIAL_CUSTOMER]({ dispatch }) {
    const customer = await dispatch(FETCH_CUSTOMER_FROM_LOCAL_STORAGE);
    if (customer) dispatch(CREATE_CUSTOMER, customer);
  },
  async [STORE_CUSTOMER_TO_LOCAL_STORAGE]({ getters }) {
    const customer = getters.getCustomer;
    if (customer) localStorage.setItem(CUSTOMER, JSON.stringify(customer));
  },
  async [SEARCH_ADDRESS_BY_POSTALCODE]({
    commit, state, getters, dispatch,
  }, postalCode) {
    if (postalCode.length > 5) {
      try {
        const { data } = await this.$axios.get(`/api/get-address/${postalCode}`);
        const result = data.results[0];
        let address = _.startCase(_.camelCase(result && result.ADDRESS));
        address = address && address.replace(postalCode, '');
        address = address.replace(' Singapore ', '');
        return address;
      } catch (e) {
        console.log(e);
        return '';
      }
    }
    return '';
  },
  async [CREATE_CUSTOMER]({ dispatch }, data) {
    const customer = await dispatch('entities/insert', {
      entity: CUSTOMERS,
      data,
    }, { root: true });
    await dispatch(STORE_CUSTOMER_TO_LOCAL_STORAGE);
    return customer;
  },
  async [UPDATE_CUSTOMER]({ dispatch }, data) {
    const bags = await dispatch('entities/update', {
      entity: CUSTOMERS,
      data,
    }, { root: true });
    await dispatch(STORE_CUSTOMER_TO_LOCAL_STORAGE);
    return bags;
  },
  async [REMOVE_CUSTOMER]({ dispatch }, $id) {
    const bags = await dispatch('entities/delete', {
      entity: CUSTOMERS,
      where: $id,
    }, { root: true });
    await dispatch(STORE_CUSTOMER_TO_LOCAL_STORAGE);
    return bags;
  },
};

export const getters = {
  getCustomer(state, getters, rootState, rootGetters) {
    return rootGetters[`entities/${CUSTOMERS}/query`]()
      .first() || {};
  },
  getCustomerDeliveryInfo(state, getters) {
    const {
      name,
      email,
      mobile,
      address,
      unit,
      postalCode,
      deliveryDate,
      city,
      address2,
      specialInstructions,
      isDeliveryToEastMalayisa,
      x,
    } = getters.getCustomer;
    const _state = getters.getCustomer.state;
    const line1 = unit !== '' ? `${address}, ${unit}` : address;
    return {
      contact: {
        name,
        email,
        mobile,
      },
      address: {
        line1,
        line2: '',
        postalCode,
        city,
        address2,
        state: _state,
        specialInstructions,
        isDeliveryToEastMalayisa,
      },
      deliveryDate: {
        byTimestamp: deliveryDate,
      },
    };
  },
};

export const getCustomerFromLocalStorage = async () => {
  const customer = localStorage.getItem(CUSTOMER);
  return customer
    ? JSON.parse(localStorage.getItem(CUSTOMER))
    : null;
};
