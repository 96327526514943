import $ from 'jquery';

// Reward types
const REWARD_TYPE__PERCENT_DISCOUNT = 'percentage-discount';
const REWARD_TYPE__AMOUNT_DISCOUNT = 'amount-discount';
const REWARD_TYPE__FREE_BAG_ITEMS = 'free-bag-items';

// Reward Item types
const REWARD_ITEM_TYPE__ALL_PRODUCTS = 'all-products';
const REWARD_ITEM_TYPE__PRODUCT_OPTION = 'product-option';

// Reward Item terms
const REWARD_ITEMS_TERMS__MIN_BOX_COUNT_SUBSEQUENT = "min-box-count--subsequent";

// Condition types
const CONDITION_TYPE__DURATION = 'duration';
const CONDITION_TYPE__PROMO_CODE = 'promo-code';
const CONDITION_TYPE__MIN_BOX_COUNT = 'min-box-count';


export default {

  getDiscountedBoxCountObject(jsonCampaigns, boxCount, timestamp, promoCode, isServerPromoCode, isValidServerPromoCode) {

    for(var i=0; i<jsonCampaigns.length; i++) {

      if (jsonCampaigns[i].target == 'box-count') {

        console.log('  +++++++++++++++');
        console.log('  CAMPAIGN ' + (i+1) + ': ' + jsonCampaigns[i].description);

        if (jsonCampaigns[i].reward !== undefined) { //some capaigns have not reward but for display purpose only
          var objReward = jsonCampaigns[i].reward;

          // Check if campaign conditions are valid
          console.log('    Validating conditions');
          var isValidCampaign = isValidBoxCountConditions(jsonCampaigns[i].conditions, timestamp, promoCode, null, null, boxCount);
          console.log('    Result: isValidCampaign = '+ isValidCampaign);

          if(isValidCampaign) {
            console.log('  Processing reward');

            switch(objReward.type) {

              // Reward is percent discount
              case REWARD_TYPE__PERCENT_DISCOUNT:
                console.log('  Reward type: ' + REWARD_TYPE__PERCENT_DISCOUNT);
                var result = getDiscountedObject__REWARD_TYPE__PERCENT_DISCOUNT(objReward, boxCount);
                console.log('  Result: discountedBoxCountObj = ...');
                console.log(result);
                return result;
                break;

              // Reward is amount discount
              case REWARD_TYPE__AMOUNT_DISCOUNT:
                console.log('  Reward type: ' + REWARD_TYPE__AMOUNT_DISCOUNT);
                var result = getDiscountedObject__REWARD_TYPE__AMOUNT_DISCOUNT(objReward, boxCount);
                console.log('  Result: discountedBoxCountObj = ...');
                console.log(result);
                return result;
                break;

              // Reward is amount discount
              case REWARD_TYPE__FREE_BAG_ITEMS:
                console.log('  Reward type: ' + REWARD_TYPE__FREE_BAG_ITEMS);
                var result = getDiscountedObject__REWARD_TYPE__FREE_BAG_ITEMS(objReward, boxCount);
                console.log('  Result: discountedBoxCountObj = ...');
                console.log(result);
                return result;
                break;
            }
          }
        }
      }
    }
    return null;
  }
}


function isValidBoxCountConditions(jsonConditions, timestamp, promoCode='', jsonBagItems=null, jsonProducts=null, boxCount=null) {

  // Check timestamp condition (Mandatory)
  var isWithinDuration = false;
  for(var i=0; i<jsonConditions.length; i++) {

    if(jsonConditions[i].type == CONDITION_TYPE__DURATION) {

      if( timestamp > jsonConditions[i].values.timestampFrom &&
          timestamp < jsonConditions[i].values.timestampTo) {
        isWithinDuration = true;
      }
    }
  }

  // Check promo code condition (optional)
  if (!isWithinDuration) {
    console.log('    + Not within duration');
    return false
  }
  else {
    console.log('    + Within duration');
    var hasPromoCodeCondition = false;

    var hasInvalidCondition = false;

    for(var i=0; i<jsonConditions.length; i++) {

      if(jsonConditions[i].type == CONDITION_TYPE__PROMO_CODE) {
        console.log('    + Condition type = ' + CONDITION_TYPE__PROMO_CODE);
        hasPromoCodeCondition = true;

        if (promoCode !== null && promoCode !== undefined && promoCode !== ''
            && jsonConditions[i].values.code != undefined
            && jsonConditions[i].values.code.toString().toUpperCase() == promoCode.toString().toUpperCase()) {

          console.log('    + Valid promo code');
        }
        else {
          hasInvalidCondition = true;
          console.log('    + Invalid promo code');
        }
      }

      else if ( jsonConditions[i].type == CONDITION_TYPE__MIN_BOX_COUNT ) {
        console.log('    + Condition type = ' + CONDITION_TYPE__MIN_BOX_COUNT);

        // check subtotal amount
        if (jsonConditions[i].values.minBoxCount == undefined ||
            boxCount < parseInt(jsonConditions[i].values.minBoxCount) ){

            hasInvalidCondition = true;
            console.log('    + Box count is insufficient');
        }
        else {
          console.log('    + Box count is sufficient');
        }
      }

      if (hasInvalidCondition) {
        return false;
      }
    }
  }
  return true;

}

// Functions to calculate discounted price
function getDiscountedObject__REWARD_TYPE__AMOUNT_DISCOUNT(objReward, boxCount) {

  // if(objReward.amount !== undefined) {
  //
  //   var discountAmount = Number(objReward.amount);
  //
  //   var discountedObject = initDiscountedBoxTotalObject();
  //
  //   for(var j=0; j<objReward.items.length; j++) {
  //
  //     switch(objReward.items[j].type) {
  //
  //       case REWARD_ITEM_TYPE__ALL_PRODUCTS:
  //         console.log('    + reward item type = ' + REWARD_ITEM_TYPE__ALL_PRODUCTS);
  //         discountedObject.discountedSubTotalPrice = calculateDiscountedSubTotalPrice(subTotalPrice, discountAmount, false);
  //         return discountedObject;
  //         break;
  //     }
  //   }
  // }
  return null;
}

// Functions to calculate discounted price
function getDiscountedObject__REWARD_TYPE__PERCENT_DISCOUNT(objReward, boxCount) {

  // if(objReward.amount !== undefined) {
  //
  //   var discountPercentage = Number(objReward.amount);
  //
  //   var discountedObject = initDiscountedBoxTotalObject();
  //
  //   for(var j=0; j<objReward.items.length; j++) {
  //
  //     switch(objReward.items[j].type) {
  //
  //       case REWARD_ITEM_TYPE__ALL_PRODUCTS:
  //         console.log('    + reward item type = ' + REWARD_ITEM_TYPE__ALL_PRODUCTS);
  //         discountedObject.discountedSubTotalPrice = calculateDiscountedSubTotalPrice(subTotalPrice, discountPercentage);
  //         return discountedObject;
  //         break;
  //
  //       case REWARD_ITEM_TYPE__PRODUCT_OPTION:
  //         console.log('    + reward item type = ' + REWARD_ITEM_TYPE__PRODUCT_OPTION);
  //         discountedObject.discountedSubTotalPrice = calculateDiscountedSubTotalPrice(subTotalPrice, discountPercentage);
  //         return discountedObject;
  //         break;
  //     }
  //   }
  // }
  return null;
}

// Functions to add free bag items
function getDiscountedObject__REWARD_TYPE__FREE_BAG_ITEMS(objReward, boxCount) {

  var discountedObject = initDiscountedBoxTotalObject();
  discountedObject.freeBagItems = [];

  for(var j=0; j<objReward.items.length; j++) {

    switch(objReward.items[j].type) {

      case REWARD_ITEM_TYPE__PRODUCT_OPTION:
        console.log('    + reward item type = ' + REWARD_ITEM_TYPE__PRODUCT_OPTION);

        var optionID = objReward.items[j].values.optionID;
        var terms = objReward.items[j].values.terms;

        // get Qty
        var qty = 0;
        if (terms.type == REWARD_ITEMS_TERMS__MIN_BOX_COUNT_SUBSEQUENT) {

          // console.log('=============boxCount');
          // console.log(boxCount);
          // console.log(terms.minBoxCount);
          // console.log(terms.unitCount);
          if (boxCount <= terms.minBoxCount) {
            qty = terms.unitCount;
          }
          else {
            qty = terms.unitCount * (Math.floor((boxCount - terms.minBoxCount) / terms.subsequentBoxCount)+1);
          }
          // console.log(qty);
        }

        var freeBagItem = {};
        freeBagItem.optionID = optionID;
        freeBagItem.qty = qty;

        discountedObject.freeBagItems.push(freeBagItem)
        break;
    }
  }

  return discountedObject;
}




function calculateDiscountedSubTotalPrice(price, discount, isPercentageDiscount=true) {

  if (isPercentageDiscount) {
    var discountFactor = 1-(discount/100);
    return Math.round(Number(price) * discountFactor * 100)/100;
  }
  else {
    return Math.round((Number(price)-discount)*100)/100;
  }
}


function initDiscountedBoxTotalObject() {
  var discountedObject = {
    discountedSubTotalPrice: null,
    freeBagItems: null
  }

  return discountedObject;
}
