import $ from 'jquery';
import Cookies from 'js-cookie';

// Display types
const DISPLAY_TYPE__CAMPAIGN_PAGE = 'campaign-page';
const DISPLAY_TYPE__PRODUCT_LIST_ITEM = 'product-list-item';
const DISPLAY_TYPE__PURCHASE_OPTIONS = 'purchase-option';
const DISPLAY_TYPE__PRODUCT_PAGE = 'product-page';
const DISPLAY_TYPE__BAG_PAGE = 'bag-page';
const DISPLAY_TYPE__SUMMARY_PAGE = 'summary-page';

// String Replace Compute types
const COMPUTE_TYPE__MIN_SUB_TOTAL_SUBSEQUENT = 'min-sub-total--subsequent';
const COMPUTE_TYPE__MIN_BOX_COUNT_SUBSEQUENT = "min-box-count--subsequent";

// Condition types
const CONDITION_TYPE__DURATION = 'duration';


export default {

  getDisplaysForCampaignPage(jsonCampaigns, promoCodeInCookie) {
    
    var objDisplays = {
      savedPromoCode: "",
      items: []
    };

    if(promoCodeInCookie != null)
      objDisplays.savedPromoCode = promoCodeInCookie.toString().toUpperCase();

     var codeInURL = null;
      
    for(var i=0; i<jsonCampaigns.length; i++) {

      var obj = jsonCampaigns[i].campaignDisplay[DISPLAY_TYPE__CAMPAIGN_PAGE];

      if(obj !== undefined) {

        if(obj.visibility == 'all')
          objDisplays.items.push(obj);
        else { //for private code where visibility =='private'

          //get from query param 
          if(codeInURL == null) { 

            var params = require('query-string').parse(location.search);
            
            if(params['code'] != undefined)
              codeInURL = params['code'].toString().toUpperCase();
          }

          //check if it's valid; if not, then don't add visibility=private item to objDisplays
          if(jsonCampaigns[i].conditions != undefined) {
            
            for(var j=0; j<jsonCampaigns[i].conditions.length; j++) {
            
              var condition = jsonCampaigns[i].conditions[j];

              if(condition.type == 'promo-code') {
                
                if(condition.values != undefined
                   && condition.values.code != undefined 
                   && condition.values.code == codeInURL) {

                  objDisplays.items.push(obj);
                }
              }
            }
          }
        }
      }
    }
    
    return objDisplays;
  },

  getDisplaysForProductListItem(productShortCode, jsonCampaigns, timestamp) {

    var objDisplays = {};

    for(var i=0; i<jsonCampaigns.length; i++) {

      // if (jsonCampaigns[i].target == 'display') {

      var displayProductListItem = jsonCampaigns[i].campaignDisplay[DISPLAY_TYPE__PRODUCT_LIST_ITEM];

      if(displayProductListItem !== undefined) {

        if(isValidDisplayConditions(jsonCampaigns[i].conditions, timestamp) === true) {

          if(displayProductListItem[productShortCode] !== undefined) {

            $.extend( objDisplays,
                      displayProductListItem[productShortCode]);
          }
        }
      }
      // }
    }
    return objDisplays;
  },

  getDisplaysForPurchaseOption(productShortCode, jsonCampaigns, timestamp) {
    var objDisplays = {};

    for(var i=0; i<jsonCampaigns.length; i++) {

      // if (jsonCampaigns[i].target == 'display') {

      var displayPurchaseOptions = jsonCampaigns[i].campaignDisplay[DISPLAY_TYPE__PURCHASE_OPTIONS];

      if(displayPurchaseOptions !== undefined) {

        if(isValidDisplayConditions(jsonCampaigns[i].conditions, timestamp) === true) {

          Object.keys(displayPurchaseOptions).forEach(function(key,index) {

            if (key.indexOf('op-'+productShortCode+'-') != -1) {

              var obj = {};

              obj[key] = displayPurchaseOptions[key];

              $.extend( objDisplays,
                        obj);
            }
          });
        }
      }
    }
    return objDisplays;
  },

  getDisplaysForProductPage(productShortCode, jsonCampaigns, timestamp, obj) {
    var objDisplays = {};

    for(var i=0; i<jsonCampaigns.length; i++) {

      var displayProductPage = jsonCampaigns[i].campaignDisplay[DISPLAY_TYPE__PRODUCT_PAGE];

      if(displayProductPage !== undefined) {

        if(isValidDisplayConditions(jsonCampaigns[i].conditions, timestamp) === true) {

          // console.log(displayProductPage);

          if (displayProductPage.shortCode == "all" ||
              displayProductPage.shortCode == productShortCode) {

            $.extend( objDisplays,
                      displayProductPage);
          }
        }
      }
    }

    return objDisplays;
  },

  getDisplaysForBagPage(bagObj, jsonCampaigns, timestamp) {
    var objDisplays = {};

    for(var i=0; i<jsonCampaigns.length; i++) {

      var displayBagPage = jsonCampaigns[i].campaignDisplay[DISPLAY_TYPE__BAG_PAGE];

      if(displayBagPage !== undefined) {

        if(isValidDisplayConditions(jsonCampaigns[i].conditions, timestamp) === true) {

          for(var j=0; j<displayBagPage.bagPageDescriptions.length; j++) {

            var desc = displayBagPage.bagPageDescriptions[j];
            var balance = 0;
            var count = -1;

            if (desc.indexOf('[SUB_TOTAL_BALANCE]') != -1 &&
                displayBagPage.stringReplace.SUB_TOTAL_BALANCE !== undefined) {

              var compute = displayBagPage.stringReplace.SUB_TOTAL_BALANCE.compute;

              if (compute.type == COMPUTE_TYPE__MIN_SUB_TOTAL_SUBSEQUENT) {

                if (bagObj.subTotalPrice < compute.minSubTotal) {
                  balance = compute.minSubTotal - bagObj.subTotalPrice;
                }
                else {
                  balance = compute.subsequentSubTotal - ((bagObj.subTotalPrice - compute.minSubTotal) % compute.subsequentSubTotal);
                }
                desc = desc.replace('[SUB_TOTAL_BALANCE]', balance.toFixed(2));
              }
            }
            if (desc.indexOf('[BOX_COUNT_BALANCE]') != -1 &&
                displayBagPage.stringReplace.BOX_COUNT_BALANCE !== undefined) {

              var compute = displayBagPage.stringReplace.BOX_COUNT_BALANCE.compute;

              if (compute.type == COMPUTE_TYPE__MIN_BOX_COUNT_SUBSEQUENT) {

                if (bagObj.totalBoxCount < compute.minBoxCount) {
                  balance = compute.minBoxCount - bagObj.totalBoxCount;
                }
                else {
                  balance = compute.subsequentBoxCount - ((bagObj.totalBoxCount - compute.minBoxCount) % compute.subsequentBoxCount);
                }
                console.log('=================balance');
                console.log(balance);
                desc = desc.replace('[BOX_COUNT_BALANCE]', balance.toFixed(0) + ' more box' + ((balance>1)?'es':''));
              }
            }
            if (desc.indexOf('[BOX_COUNT]') != -1 &&
                displayBagPage.stringReplace.BOX_COUNT !== undefined) {

              var compute = displayBagPage.stringReplace.BOX_COUNT.compute;

              if (compute.type == COMPUTE_TYPE__MIN_SUB_TOTAL_SUBSEQUENT) {

                if (bagObj.subTotalPrice < compute.minSubTotal) {
                  if (balance != 0) {
                    desc = displayBagPage.invalidConditionDescription;
                  }
                }
                else {
                  count = compute.unitCount * (Math.floor((bagObj.subTotalPrice - compute.minSubTotal) / compute.subsequentSubTotal)+1);
                }
              }
              else if (compute.type == COMPUTE_TYPE__MIN_BOX_COUNT_SUBSEQUENT) {

                if (bagObj.totalBoxCount < compute.minBoxCount) {
                  if (balance != 0) {
                    desc = displayBagPage.invalidConditionDescription;
                  }
                }
                else {
                  count = compute.unitCount * (Math.floor((bagObj.totalBoxCount - compute.minBoxCount) / compute.subsequentBoxCount)+1);
                }
              }

              if (count > 0) {
                desc = desc.replace('[BOX_COUNT]', count);
                desc = desc.replace('[BOX_COUNT_PLUS_1]', count+1);
                desc = desc.replace('[BOX_COUNT_PLUS_2]', count+2);
              }
            }

            displayBagPage.bagPageDescriptions[j] = desc;
          }

          $.extend( objDisplays,
                    displayBagPage);
        }
      }
    }
    return objDisplays;
  },

  getDisplaysForSummaryPage(bagObj, jsonCampaigns, timestamp) {
    var objDisplays = {};

    for(var i=0; i<jsonCampaigns.length; i++) {

      var displaySummaryPage = jsonCampaigns[i].campaignDisplay[DISPLAY_TYPE__SUMMARY_PAGE];

      if(displaySummaryPage !== undefined) {

        if(isValidDisplayConditions(jsonCampaigns[i].conditions, timestamp) === true) {

          for(var j=0; j<displaySummaryPage.summaryPageDescriptions.length; j++) {

            var desc = displaySummaryPage.summaryPageDescriptions[j];

            if (desc.indexOf('[BOX_COUNT]') != -1 &&
                displaySummaryPage.stringReplace.BOX_COUNT !== undefined) {

              var compute = displaySummaryPage.stringReplace.BOX_COUNT.compute;

              if (compute.type == COMPUTE_TYPE__MIN_SUB_TOTAL_SUBSEQUENT) {

                var count = -1;

                if (bagObj.subTotalPrice < compute.minSubTotal) {
                  desc = displaySummaryPage.invalidConditionDescription;
                }
                else {
                  count = compute.unitCount * (Math.floor((bagObj.subTotalPrice - compute.minSubTotal) / compute.subsequentSubTotal)+1);
                  // desc = desc.replace('[BOX_COUNT]', count);
                }
              }
              else if (compute.type == COMPUTE_TYPE__MIN_BOX_COUNT_SUBSEQUENT) {

                if (bagObj.totalBoxCount < compute.minBoxCount) {
                  desc = displaySummaryPage.invalidConditionDescription;
                }
                else {
                  count = compute.unitCount * (Math.floor((bagObj.totalBoxCount - compute.minBoxCount) / compute.subsequentBoxCount)+1);
                }
              }
              if (count > 0) {
                desc = desc.replace('[BOX_COUNT]', count);
                desc = desc.replace('[BOX_COUNT_PLUS_1]', count+1);
                desc = desc.replace('[BOX_COUNT_PLUS_2]', count+2);
              }
            }

            displaySummaryPage.summaryPageDescriptions[j] = desc;
          }

          $.extend( objDisplays,
                    displaySummaryPage);
        }
      }
    }
    return objDisplays;
  },
}


function isValidDisplayConditions(jsonConditions, timestamp, promoCode, jsonBagItems, jsonProducts, initialSubTotalPrice=null) {

  // Check timestamp condition (Mandatory)
  var isWithinDuration = false;
  for(var i=0; i<jsonConditions.length; i++) {

    if(jsonConditions[i].type == CONDITION_TYPE__DURATION) {

      if( timestamp > jsonConditions[i].values.timestampFrom &&
          timestamp < jsonConditions[i].values.timestampTo) {
        isWithinDuration = true;
      }
    }
  }

  return isWithinDuration;
}
