import Cookies from 'js-cookie';
import { TIME } from './time';

export const FETCH_BAG_FROM_LOCAL_STORAGE = 'fetchBagFromLocalStorage';
export const STORE_BAG_TO_LOCAL_STORAGE = 'storeBagToLocalStorage';
export const SET_INITIAL_BAG = 'setInitialBag';
export const CREATE_BAG = 'createBag';
export const ADD_BAG_QUANTITY = 'addBagQuantity';
export const SUBTRACT_BAG_QUANTITY = 'subtractBagQuantity';
export const REMOVE_BAG = 'removeBag';
export const DELETE_ALL_BAGS = 'deleteAllBags';
export const BAGS = 'bags';

export const state = () => ({});

export const mutations = {};

export const actions = {
  async [FETCH_BAG_FROM_LOCAL_STORAGE]() {
    const bags = localStorage.getItem(BAGS);
    return bags
      ? JSON.parse(localStorage.getItem(BAGS))
      : null;
  },
  async [SET_INITIAL_BAG]({ dispatch }) {
    const bags = await dispatch(FETCH_BAG_FROM_LOCAL_STORAGE);
    if (bags) dispatch(CREATE_BAG, bags);
  },
  async [STORE_BAG_TO_LOCAL_STORAGE]({ getters }) {
    const bags = getters.getAllBags || [];
    localStorage.setItem(BAGS, JSON.stringify(bags));
  },
  async [CREATE_BAG]({ dispatch }, data) {
    const bags = await dispatch('entities/insert', {
      entity: BAGS,
      data,
    }, { root: true });
    await dispatch(STORE_BAG_TO_LOCAL_STORAGE);
    return bags;
  },
  async [ADD_BAG_QUANTITY]({ dispatch }, data) {
    const bags = await dispatch('entities/update', {
      entity: BAGS,
      data,
    }, { root: true });
    await dispatch(STORE_BAG_TO_LOCAL_STORAGE);
    return bags;
  },
  async [SUBTRACT_BAG_QUANTITY]({ dispatch }, data) {
    const bags = await dispatch('entities/update', {
      entity: BAGS,
      data,
    }, { root: true });
    await dispatch(STORE_BAG_TO_LOCAL_STORAGE);
    return bags;
  },
  async [REMOVE_BAG]({ dispatch }, $id) {
    const bags = await dispatch('entities/delete', {
      entity: BAGS,
      where: $id,
    }, { root: true });
    await dispatch(STORE_BAG_TO_LOCAL_STORAGE);
    return bags;
  },
  async [DELETE_ALL_BAGS]({ dispatch, getters }) {
    const bagIds = getters.getAllBags.map((item) => item.$id);
    await Promise.all(bagIds.map(async ($id) => dispatch('entities/delete', {
      entity: BAGS,
      where: $id,
    }, { root: true })));
    return dispatch(STORE_BAG_TO_LOCAL_STORAGE);
  },
};

export const getters = {
  getAllBags(state, getters, rootState, rootGetters) {
    return rootGetters[`entities/${BAGS}/query`]()
      .get();
  },
  getAllBagItems(state, getters, rootState, rootGetters) {
    const bags = getters.getAllBags || [];
    const timestamp = rootGetters[`${TIME}/getTimeStamp`];
    return {
      timestamp,
      items: bags,
    };
  },
};
