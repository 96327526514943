
export const SHARE_REWARD = 'shareReward';
export const STORE_SHARE_REWARD_OBJECT = 'storeShareRewardObject';
export const SHARE_REWARD_OBJECT = 'storeShareRewardObject';
export const FETCH_SHARE_REWARD_OBJECT_FROM_LOCAL_STORAGE = 'fetchShareRewardObjectFromLocalStorage';
export const SET_INITIAL_SHARE_REWARD = 'setInitialShareReward';
export const SET_SHARE_REWARD = 'setShareReward';
export const DELETE_ALL_SHARE_REWARD = 'deleteAllShareReward';

export const state = () => ({
  shareReward: null,
});

export const mutations = {
  [SET_SHARE_REWARD](state, shareReward) {
    state.shareReward = shareReward;
  },
};

export const actions = {
  [FETCH_SHARE_REWARD_OBJECT_FROM_LOCAL_STORAGE]() {
    const shareRewardObject = localStorage.getItem(SHARE_REWARD_OBJECT);
    return shareRewardObject
      ? JSON.parse(shareRewardObject)
      : null;
  },
  async [STORE_SHARE_REWARD_OBJECT]({ commit, dispatch }, shareReward) {
    await localStorage.setItem(SHARE_REWARD_OBJECT, JSON.stringify(shareReward));
    return commit(SET_SHARE_REWARD, shareReward);
  },
  async [SET_INITIAL_SHARE_REWARD]({ commit, dispatch }) {
    const shareReward = await dispatch(FETCH_SHARE_REWARD_OBJECT_FROM_LOCAL_STORAGE);
    return commit(SET_SHARE_REWARD, shareReward);
  },
  async [DELETE_ALL_SHARE_REWARD]({ dispatch }) {
    return dispatch(STORE_SHARE_REWARD_OBJECT, []);
  },
};

export const getters = {
  getShareReward(state) {
    return state.shareReward;
  },
};
