import jwt from 'jsonwebtoken';
// TODO
//  - Delivery fee is free when subtotal is over RM50 for ONLY West Malaysia;
//  - RM20 for East Malaysia and no free delivery fee

// PSUEDOCODE
// - FREE = subtotal > 50 and state === "West Malaysia"
// - 20RM = subtotal > 50 and state === "East Malaysia"
// - East Malaysia = Sarawak/Sabah/Labuan
export const getIsDeliveryToEastMalayisa = (state) => [
  'Sarawak',
  'Sabah',
  'Labuan',
].includes(state);

export const decodeJWT = (sessionId) => {
  const token = jwt.sign({ sessionId }, 'shhhhh');
  const decoded = jwt.decode(token);
  // const verify = jwt.verify(token, 'wrong-secret');
  console.log(token);
  console.log(decoded);
};

export const getHostName = () => (process.env.API_URL === 'http://localhost:5000'
  ? process.env.API_URL
  : `//${window.location.hostname}`);

export const getPlatformHostName = () => (window.location.hostname === 'localhost'
  ? 'http://localhost:3000'
  : `https://${window.location.hostname}`);

export const delay = (ms) => new Promise((resolve, reject) => {
  setTimeout(() => {
    resolve();
  }, ms);
});

export const fixMissingData = (data) => {
  data.stockCount = data.stockCount || 1000;
  data.recommendedProductsTrending = data.recommendedProductsTrending
    .map((x) => {
      x.series = x.series ? x.series : {};
      x.thumbnail = x.thumbnail ? x.thumbnail : {
        fileName: '404@2x.jpg',
      };
      x.label = x.label ? x.label : {};
      x.tag = x.tag ? x.tag : {};
      return {
        ...x,
      };
    });
  return data;
};
