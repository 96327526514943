import VuexORM from '@vuex-orm/core';

import Bag from '@/models/Bag';
import Customer from '@/models/Customer';

// Create a new database instance.
const database = new VuexORM.Database();

// Register Models to the database.
database.register(Bag);
database.register(Customer);

export const plugins = [VuexORM.install(database)];


export const SHOW_ADD_ON = 'showAddOn';
export const HIDE_ADD_ON = 'hideAddOn';
export const SHOW_SHARE_REWARD = 'showShareReward';
export const HIDE_SHARE_REWARD = 'hideShareReward';
export const SET_SHARE_REWARD_ITEMS = 'setShareRewardItems';
export const SET_CUSTOMER_SHARE_REWARD_DATA = 'setCustomerShareRewardData';

export const state = () => ({
  isShowAddOn: false,
  isShowShareReward: false,
  shareRewardItems: [],
  customerShareRewardData: {},
});

export const mutations = {
  [SHOW_ADD_ON](state) {
    state.isShowAddOn = true;
  },
  [HIDE_ADD_ON](state) {
    state.isShowAddOn = false;
  },
  [SHOW_SHARE_REWARD](state) {
    state.isShowShareReward = true;
  },
  [HIDE_SHARE_REWARD](state) {
    state.isShowShareReward = false;
  },
  [SET_SHARE_REWARD_ITEMS](state, payload) {
    state.shareRewardItems = [...payload];
  },
  [SET_CUSTOMER_SHARE_REWARD_DATA](state, payload) {
    state.customerShareRewardData = { ...payload };
  },
};

export const actions = {
  [SHOW_ADD_ON]({ commit }) {
    return commit(SHOW_ADD_ON);
  },
  [HIDE_ADD_ON]({ commit }) {
    return commit(HIDE_ADD_ON);
  },
  [SHOW_SHARE_REWARD]({ commit }) {
    return commit(SHOW_SHARE_REWARD);
  },
  [HIDE_SHARE_REWARD]({ commit }) {
    return commit(HIDE_SHARE_REWARD);
  },
  [SET_SHARE_REWARD_ITEMS]({ commit }, payload) {
    return commit(SET_SHARE_REWARD_ITEMS, payload);
  },
  [SET_CUSTOMER_SHARE_REWARD_DATA]({ commit }, payload) {
    return commit(SET_CUSTOMER_SHARE_REWARD_DATA, payload);
  },
};

export const storeShowAddOn = async ($store) => $store.dispatch(SHOW_ADD_ON);
export const storeHideAddOn = async ($store) => $store.dispatch(HIDE_ADD_ON);
export const storeShowShareReward = async ($store) => $store.dispatch(SHOW_SHARE_REWARD);
export const storeHideShareReward = async ($store) => $store.dispatch(HIDE_SHARE_REWARD);
export const setShareRewardItems = async ($store, payload) => $store.dispatch(SET_SHARE_REWARD_ITEMS, payload);
export const setCustomerShareRewardData = async ($store, payload) => $store.dispatch(SET_CUSTOMER_SHARE_REWARD_DATA, payload);
