import Vue from 'vue';
import Nav from 'doki-js/nav';
import $ from 'jquery';
import UtilCheckout from 'doki-js/util-checkout';
import { BAGS, SET_INITIAL_BAG } from '@/store/bags';
import { CUSTOMERS, SET_INITIAL_CUSTOMER } from '@/store/customers';
import { FETCH_GIFT_OBJECT_FROM_LOCAL_STORAGE, GIFT, SET_INITIAL_GIFT } from '../store/gift';
import { SET_INITIAL_SHARE_REWARD, SHARE_REWARD } from '../store/shareReward';
// import { FETCH_SERVER_TIME, TIME } from '@/store/time';

const doki = {
  install(vue) {
    vue.mixin({
      async fetch({ store }) {
        const allPromises = [
          // store.dispatch(`${TIME}/${FETCH_SERVER_TIME}`),
          store.dispatch(`${BAGS}/${SET_INITIAL_BAG}`),
          store.dispatch(`${CUSTOMERS}/${SET_INITIAL_CUSTOMER}`),
          store.dispatch(`${GIFT}/${SET_INITIAL_GIFT}`),
          store.dispatch(`${SHARE_REWARD}/${SET_INITIAL_SHARE_REWARD}`),
        ];
        return Promise.all(allPromises);
      },
      methods: {
        setBagItemCount() {
          const addOnItemsQtyInCookies = UtilCheckout.getAddOnItemsQtyInCookies();
          Nav.setBagItemCount(this.getBagItemsCount + addOnItemsQtyInCookies);
        },
        goTo(page) {
          this.$router.push({
            path: page,
          });
          $('body').removeClass('no-scroll');
        },
      },
      computed: {
        getGift() {
          return this.$store.getters[`${GIFT}/getGift`];
        },
        getCustomer() {
          return this.$store.getters[`${CUSTOMERS}/getCustomer`];
        },
        getBagItemsCount() {
          if (!this.$store) return 0;
          const bags = this.$store.getters[`${BAGS}/getAllBags`];
          return bags
            .map((item) => item.qty)
            .reduce((total, num) => total + num, 0);
        },
        getBagItemsCountItemLabel() {
          return this.getBagItemsCount > 1 ? 'items' : 'item';
        },
      },
    });
  },
};

Vue.use(doki);
