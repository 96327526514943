import $ from 'jquery';

// Reward types
const REWARD_TYPE__PERCENT_DISCOUNT = 'percentage-discount';
const REWARD_TYPE__AMOUNT_DISCOUNT = 'amount-discount';
const REWARD_TYPE__FREE_BAG_ITEMS = 'free-bag-items';

// Reward Item types
const REWARD_ITEM_TYPE__ALL_PRODUCTS = 'all-products';
const REWARD_ITEM_TYPE__PRODUCT_OPTION = 'product-option';

// Reward Item terms
const REWARD_ITEMS_TERMS__MIN_SUB_TOTAL_SUBSEQUENT = "min-sub-total--subsequent";
const REWARD_ITEMS_TERMS__WITH_SERIES_PURCHASE = "with-series-purchase";

// Condition types
const CONDITION_TYPE__DURATION = 'duration';
const CONDITION_TYPE__PROMO_CODE = 'promo-code';
const CONDITION_TYPE__MIN_SUB_TOTAL = 'min-sub-total';
const CONDITION_TYPE__VOUCHER_CODE = 'voucher-code';

var jsonProducts;

export default {

  getDiscountedSubTotalObject(jsonCampaigns, _jsonProducts, jsonBagItems, subTotalPrice, timestamp, promoCode, isServerPromoCode, isValidServerPromoCode) {

    jsonProducts = _jsonProducts;

    for(var i=0; i<jsonCampaigns.length; i++) {

      if (jsonCampaigns[i].target == 'subtotal') {

        console.log('  +++++++++++++++');
        console.log('  CAMPAIGN ' + (i+1) + ': ' + jsonCampaigns[i].description);

        if (jsonCampaigns[i].reward !== undefined) { //some capaigns have not reward but for display purpose only
          var objReward = jsonCampaigns[i].reward;

          // Check if campaign conditions are valid
          console.log('    Validating conditions');
          var isValidCampaign = isValidSubTotalConditions(jsonCampaigns[i].conditions, timestamp, promoCode, null, null, subTotalPrice);
          console.log('    Result: isValidCampaign = '+ isValidCampaign);

          if(isValidCampaign) {
            console.log('  Processing reward');

            switch(objReward.type) {

              // Reward is percent discount
              case REWARD_TYPE__PERCENT_DISCOUNT:
                console.log('  Reward type: ' + REWARD_TYPE__PERCENT_DISCOUNT);
                var result = getDiscountedObject__REWARD_TYPE__PERCENT_DISCOUNT(objReward, subTotalPrice);
                console.log('  Result: discountedSubTotalObj = ...');
                console.log(result);
                return result;
                break;

              // Reward is amount discount
              case REWARD_TYPE__AMOUNT_DISCOUNT:
                console.log('  Reward type: ' + REWARD_TYPE__AMOUNT_DISCOUNT);
                var result = getDiscountedObject__REWARD_TYPE__AMOUNT_DISCOUNT(objReward, subTotalPrice);
                console.log('  Result: discountedSubTotalObj = ...');
                console.log(result);
                return result;
                break;

              // Reward is amount discount
              case REWARD_TYPE__FREE_BAG_ITEMS:
                console.log('  Reward type: ' + REWARD_TYPE__FREE_BAG_ITEMS);
                var result = getDiscountedObject__REWARD_TYPE__FREE_BAG_ITEMS(objReward, jsonBagItems, subTotalPrice);
                console.log('  Result: discountedSubTotalObj = ...');
                console.log(result);
                return result;
                break;
            }
          }
        }
      }
    }
    return null;
  }
}


function isValidSubTotalConditions(jsonConditions, timestamp, promoCode='', jsonBagItems=null, jsonProducts=null, subTotalPrice=null) {

  // Check timestamp condition (Mandatory)
  var isWithinDuration = false;
  for(var i=0; i<jsonConditions.length; i++) {

    if(jsonConditions[i].type == CONDITION_TYPE__DURATION) {

      if( timestamp > jsonConditions[i].values.timestampFrom &&
          timestamp < jsonConditions[i].values.timestampTo) {
        isWithinDuration = true;
      }
    }
  }

  // Check promo code condition (optional)
  if (!isWithinDuration) {
    console.log('    + Not within duration');
    return false
  }
  else {
    console.log('    + Within duration');
    var hasPromoCodeCondition = false;

    var hasInvalidCondition = false;

    for(var i=0; i<jsonConditions.length; i++) {

      // console.log('jsonConditions[i].type: ' + jsonConditions[i].type);

      if(jsonConditions[i].type == CONDITION_TYPE__PROMO_CODE) {
        console.log('    + Condition type = ' + CONDITION_TYPE__PROMO_CODE);
        hasPromoCodeCondition = true;

        if (promoCode !== null && promoCode !== undefined && promoCode !== ''
            && jsonConditions[i].values.code != undefined
            && jsonConditions[i].values.code.toString().toUpperCase() == promoCode.toString().toUpperCase()) {

          console.log('    + Valid promo code');
        }
        else {
          hasInvalidCondition = true;
          console.log('    + Invalid promo code');
        }
      }

      else if ( jsonConditions[i].type == CONDITION_TYPE__MIN_SUB_TOTAL ) {
        console.log('    + Condition type = ' + CONDITION_TYPE__MIN_SUB_TOTAL);

        // check subtotal amount
        if (jsonConditions[i].values.minSubTotal == undefined ||
            subTotalPrice < parseInt(jsonConditions[i].values.minSubTotal) ){

            hasInvalidCondition = true;
            console.log('    + Subtotal is insufficient');
        }
        else {
          console.log('    + Subtotal is sufficient');
        }
      }

      else if ( jsonConditions[i].type == CONDITION_TYPE__VOUCHER_CODE ) {
        console.log('    + Condition type = ' + CONDITION_TYPE__VOUCHER_CODE);

        // check prefix
        if (promoCode !== null && promoCode !== undefined && promoCode !== ''
            && jsonConditions[i].values.prefix != undefined
            && jsonConditions[i].values.saltNumChar != undefined
            && promoCode.length > (jsonConditions[i].values.prefix.toString().length + jsonConditions[i].values.saltNumChar)
            && promoCode.toString().toUpperCase().indexOf(jsonConditions[i].values.prefix) != -1) {

          console.log('    + Valid voucher code prefix');

          // check salt validity
          promoCode = promoCode.toString().toUpperCase();
          var code = promoCode.split(jsonConditions[i].values.prefix)[1];
          // console.log('code: ' + code);
          var salt = code.substring(code.length-jsonConditions[i].values.saltNumChar).toLowerCase();
          // console.log('salt: ' + salt);
          var rawCode = code.substring(0, code.length-jsonConditions[i].values.saltNumChar).toLowerCase();
          // console.log('rawCode: ' + rawCode);

          var rawCodes = rawCode.split('');
          // console.log(rawCodes);

          var ascii = [];
          var saltAscii = 1;

          for(var i=0; i<rawCodes.length; i++) {
            ascii.push(null);
            ascii[i] = rawCodes[i].charCodeAt(0);
            saltAscii *= ascii[i];
          }

          // console.log(ascii);
          // console.log('saltAscii: ' + saltAscii);

          // generate salt, a-z
          saltAscii %= 26;
          // console.log(saltAscii);
          saltAscii += 97;
          saltAscii = Math.min(saltAscii, 122);
          saltAscii = Math.max(saltAscii, 97);

          var computedSalt = String.fromCharCode(saltAscii);
          // console.log('salt: ' + salt);

          if (computedSalt == salt) {
            console.log('    + Salt matched');
          }
          else {
            hasInvalidCondition = true;
            console.log('    + Salt mismatched');
          }
        }
        else {
          hasInvalidCondition = true;
          console.log('    + Invalid voucher code prefix');
        }
      }


      if (hasInvalidCondition) {
        return false;
      }
    }
  }
  return true;

}

// Functions to calculate discounted price
function getDiscountedObject__REWARD_TYPE__AMOUNT_DISCOUNT(objReward, subTotalPrice) {

  if(objReward.amount !== undefined) {

    var discountAmount = Number(objReward.amount);

    var discountedObject = initDiscountedSubTotalObject();

    for(var j=0; j<objReward.items.length; j++) {

      switch(objReward.items[j].type) {

        case REWARD_ITEM_TYPE__ALL_PRODUCTS:
          console.log('    + reward item type = ' + REWARD_ITEM_TYPE__ALL_PRODUCTS);
          discountedObject.discountedSubTotalPrice = calculateDiscountedSubTotalPrice(subTotalPrice, discountAmount, false);
          return discountedObject;
          break;
      }
    }
  }
  return null;
}

// Functions to calculate discounted price
function getDiscountedObject__REWARD_TYPE__PERCENT_DISCOUNT(objReward, subTotalPrice) {

  if(objReward.amount !== undefined) {

    var discountPercentage = Number(objReward.amount);

    var discountedObject = initDiscountedSubTotalObject();

    for(var j=0; j<objReward.items.length; j++) {

      switch(objReward.items[j].type) {

        case REWARD_ITEM_TYPE__ALL_PRODUCTS:
          console.log('    + reward item type = ' + REWARD_ITEM_TYPE__ALL_PRODUCTS);
          discountedObject.discountedSubTotalPrice = calculateDiscountedSubTotalPrice(subTotalPrice, discountPercentage);
          return discountedObject;
          break;

        case REWARD_ITEM_TYPE__PRODUCT_OPTION:
          console.log('    + reward item type = ' + REWARD_ITEM_TYPE__PRODUCT_OPTION);
          discountedObject.discountedSubTotalPrice = calculateDiscountedSubTotalPrice(subTotalPrice, discountPercentage);
          return discountedObject;
          break;
      }
    }
  }
  return null;
}

// Functions to add free bag items
function getDiscountedObject__REWARD_TYPE__FREE_BAG_ITEMS(objReward, jsonBagItems, subTotalPrice) {

  var discountedObject = initDiscountedSubTotalObject();
  discountedObject.freeBagItems = [];

  for(var j=0; j<objReward.items.length; j++) {

    switch(objReward.items[j].type) {

      case REWARD_ITEM_TYPE__PRODUCT_OPTION:
        console.log('    + reward item type = ' + REWARD_ITEM_TYPE__PRODUCT_OPTION);

        var optionID = objReward.items[j].values.optionID;
        var terms = objReward.items[j].values.terms;

        // get Qty
        var qty = 0;
        if (terms.type == REWARD_ITEMS_TERMS__MIN_SUB_TOTAL_SUBSEQUENT) {

          if (subTotalPrice <= terms.minSubTotal) {
            qty = terms.unitCount;
          }
          else {
            qty = terms.unitCount * (Math.floor((subTotalPrice - terms.minSubTotal) / terms.subsequentSubTotal)+1);
          }
        }
        else if (terms.type == REWARD_ITEMS_TERMS__WITH_SERIES_PURCHASE) {
          //check if bag items contains at least one sku from series
          var seriesName = terms.seriesName;
          var hasSeries = false;

          for(var i=jsonBagItems.length-1; i>=0; i--) {

            var shortCode = jsonBagItems[i].optionID.split('-')[1];

            if (jsonProducts[shortCode].series.name == seriesName) {
              hasSeries = true
              break;
            }
          }

          if(!hasSeries) {
            return discountedObject;
          }
          qty = terms.unitCount;
        }

        var freeBagItem = {};
        freeBagItem.optionID = optionID;
        freeBagItem.qty = qty;

        discountedObject.freeBagItems.push(freeBagItem)
        break;
    }
  }

  if (objReward.remarks != undefined)
    discountedObject.remarks = objReward.remarks;

  return discountedObject;
}




function calculateDiscountedSubTotalPrice(price, discount, isPercentageDiscount=true) {

  if (isPercentageDiscount) {
    var discountFactor = 1-(discount/100);
    return Math.round(Number(price) * discountFactor * 100)/100;
  }
  else {
    return Math.round((Number(price)-discount)*100)/100;
  }
}


function initDiscountedSubTotalObject() {
  var discountedObject = {
    discountedSubTotalPrice: null,
    freeBagItems: null
  }

  return discountedObject;
}
