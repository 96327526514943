// import moment from 'moment';
import { getDay, addDays, getUnixTime } from 'date-fns';
import { Model } from '@vuex-orm/core';
import pkg from '@/package';

export default class Customer extends Model {
  static entity = 'customers';

  static primaryKey = '$id';

  static fields() {
    return {
      $id: this.attr(null),
      ver: this.string(pkg.version),
      createdAt: this.number(null),
      updatedAt: this.number(null),
      postalCode: this.string(''),
      address: this.string(''),
      unit: this.string(''),
      name: this.string(''),
      email: this.string(''),
      mobile: this.string(''),
      isSubscribe: this.boolean(true),
      city: this.string(''),
      address2: this.string(''),
      state: this.string(''),
      specialInstructions: this.string(''),
      isDeliveryToEastMalayisa: this.boolean(false),
    };
  }

  get deliveryDate() {
    let day = 5;
    if (getDay(new Date()) === 6) {
      day += 1;
    } else if ([1, 2, 3, 4, 5].includes(getDay(new Date()))) {
      day += 2;
    }
    return getUnixTime(addDays(new Date(), day));
  }
}
