import { AUTH, GET_JWT_TOKEN } from '../store/auth';

export default ({ $axios, store }) => {
  $axios.onRequest(async (config) => {
    const token = await store.dispatch(`${AUTH}/${GET_JWT_TOKEN}`);
    if (token) {
      console.log('generate token!');
      config.headers.common.Authorization = token;
    }
    return config;
  });
};
