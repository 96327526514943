import $ from 'jquery';

// Reward types
const REWARD_TYPE__PERCENT_DISCOUNT = 'percentage-discount';
const REWARD_TYPE__AMOUNT_DISCOUNT = 'amount-discount';
const REWARD_TYPE__FREE_BAG_ITEMS = 'free-bag-items';

// Reward Item types
const REWARD_ITEM_TYPE__PRODUCT_SKU = 'product-sku';
const REWARD_ITEM_TYPE__PRODUCT_OPTION = 'product-option';
const REWARD_ITEM_TYPE__ALL_PRODUCTS = 'all-products';

// Reward Item terms
const REWARD_ITEMS_TERMS__WITH_SERIES_PURCHASE = "with-series-purchase";
const REWARD_ITEMS_TERMS__WITH_OPTION_PURCHASE_SUBSEQUENT = "with-option-purchase--subsequent";

// Condition types
const CONDITION_TYPE__DURATION = 'duration';
const CONDITION_TYPE__PROMO_CODE = 'promo-code';
const CONDITION_TYPE__MIN_SUB_TOTAL = 'min-sub-total';
const CONDITION_TYPE__WITH_OPTION_PURCHASE = 'with-option-purchase';
const CONDITION_TYPE__VOUCHER_CODE = 'voucher-code';


export default {

  getDiscountedOptionObject(jsonCampaigns, jsonProducts, jsonBagItems, objOption, initialSubTotalPrice, timestamp, promoCode, isServerPromoCode, isValidServerPromoCode) {


    if (isServerPromoCode) {
      if (isValidServerPromoCode) {
        return getDiscountedObject__SERVER_PROMO_CODE__NEWSLETTER(promoCode, objOption);
      }
      else {
        return null;
      }
    }

    for(var i=0; i<jsonCampaigns.length; i++) {

      if (jsonCampaigns[i].target == 'option') {

        console.log('  +++++++++++++++');
        console.log('  CAMPAIGN ' + (i+1) + ': ' + jsonCampaigns[i].description);

        if (jsonCampaigns[i].reward !== undefined) { //some capaigns have not reward but for display purpose only
          var objReward = jsonCampaigns[i].reward;

          // Check if campaign conditions are valid
          console.log('    Validating conditions');
          var isValidCampaign = isValidOptionConditions(jsonCampaigns[i].conditions, timestamp, promoCode, jsonBagItems, null, initialSubTotalPrice);
          console.log('    Result: isValidCampaign = '+ isValidCampaign);

          if(isValidCampaign) {
            console.log('  Processing reward');
            // Get discountedOptionPrice
            switch(objReward.type) {

              // Reward is percent discount
              case REWARD_TYPE__PERCENT_DISCOUNT:
                console.log('  Reward type: ' + REWARD_TYPE__PERCENT_DISCOUNT);
                var result = getDiscountedObject__REWARD_TYPE__PERCENT_DISCOUNT(objReward, jsonProducts, jsonBagItems, objOption);
                console.log('  Result: discountedObj = ...');
                console.log(result);
                return result;
                break;

              // Reward is amount discount
              case REWARD_TYPE__AMOUNT_DISCOUNT:
                console.log('  Reward type: ' + REWARD_TYPE__AMOUNT_DISCOUNT);
                var result = getDiscountedObject__REWARD_TYPE__AMOUNT_DISCOUNT(objReward, objOption);
                console.log('  Result: discountedObj = ...');
                console.log(result);
                return result;
                break;

              // Reward is amount discount
              case REWARD_TYPE__FREE_BAG_ITEMS:
                console.log('  Reward type: ' + REWARD_TYPE__FREE_BAG_ITEMS);
                var result = getDiscountedObject__REWARD_TYPE__FREE_BAG_ITEMS(objReward, jsonProducts, jsonBagItems, objOption);
                console.log('  Result: discountedBoxCountObj = ...');
                console.log(result);
                return result;
                break;
            }
          }
        }
      }
    }
    return null;
  }
}


function isValidOptionConditions(jsonConditions, timestamp, promoCode='', jsonBagItems=null, jsonProducts=null, initialSubTotalPrice=null) {

  // Check timestamp condition (Mandatory)
  var isWithinDuration = false;
  for(var i=0; i<jsonConditions.length; i++) {

    if(jsonConditions[i].type == CONDITION_TYPE__DURATION) {

      if( timestamp > jsonConditions[i].values.timestampFrom &&
          timestamp < jsonConditions[i].values.timestampTo) {
        isWithinDuration = true;
      }
    }
  }

  // Check promo code condition (optional)
  if (!isWithinDuration) {
    console.log('    + Not within duration');
    return false
  }
  else {
    console.log('    + Within duration');
    var hasPromoCodeCondition = false;

    var hasInvalidCondition = false;

    for(var i=0; i<jsonConditions.length; i++) {

      if(jsonConditions[i].type == CONDITION_TYPE__PROMO_CODE) {
        console.log('    + Condition type = ' + CONDITION_TYPE__PROMO_CODE);
        hasPromoCodeCondition = true;

        if (promoCode !== null && promoCode !== undefined && promoCode !== ''
            && jsonConditions[i].values.code != undefined
            && jsonConditions[i].values.code.toString().toUpperCase() == promoCode.toString().toUpperCase()) {

          console.log('    + Valid promo code');
        }
        else {
          hasInvalidCondition = true;
          console.log('    + Invalid promo code');
        }
      }

      else if ( jsonConditions[i].type == CONDITION_TYPE__MIN_SUB_TOTAL ) {
        console.log('    + Condition type = ' + CONDITION_TYPE__MIN_SUB_TOTAL);

        // check subtotal amount
        if (jsonConditions[i].values.minSubTotal == undefined ||
            initialSubTotalPrice < parseInt(jsonConditions[i].values.minSubTotal) ){

            hasInvalidCondition = true;
            console.log('    + Subtotal is insufficient');
        }
        else {
          console.log('    + Subtotal is sufficient');
        }
      }

      else if ( jsonConditions[i].type == CONDITION_TYPE__WITH_OPTION_PURCHASE ) {
        console.log('    + Condition type = ' + CONDITION_TYPE__WITH_OPTION_PURCHASE);

        var optionCode = jsonConditions[i].values.option;
        var minUnitQty = parseInt(jsonConditions[i].values.minUnitQty);
        var unitFoundCount = 0;

        for (var j=0; j<jsonBagItems.length; j++) {

          if (jsonBagItems[j].optionID == optionCode) {
            unitFoundCount += parseInt(jsonBagItems[j].qty);
          }
        }

        if (unitFoundCount < minUnitQty) {
          hasInvalidCondition = true;
          console.log('    + Insufficient qty of option purchased');
        }
        else {
          console.log('    + Sufficient qty of option purchased');
        }
      }

      else if ( jsonConditions[i].type == CONDITION_TYPE__VOUCHER_CODE ) {
        console.log('    + Condition type = ' + CONDITION_TYPE__VOUCHER_CODE);

        // check prefix
        if (promoCode !== null && promoCode !== undefined && promoCode !== ''
            && jsonConditions[i].values.prefix != undefined
            && jsonConditions[i].values.saltNumChar != undefined
            && promoCode.length > (jsonConditions[i].values.prefix.toString().length + jsonConditions[i].values.saltNumChar)
            && promoCode.toString().toUpperCase().indexOf(jsonConditions[i].values.prefix) != -1) {

          console.log('    + Valid voucher code prefix');

          // check salt validity
          promoCode = promoCode.toString().toUpperCase();
          var code = promoCode.split(jsonConditions[i].values.prefix)[1];
          // console.log('code: ' + code);
          var salt = code.substring(code.length-jsonConditions[i].values.saltNumChar).toLowerCase();
          // console.log('salt: ' + salt);
          var rawCode = code.substring(0, code.length-jsonConditions[i].values.saltNumChar).toLowerCase();
          // console.log('rawCode: ' + rawCode);

          var rawCodes = rawCode.split('');
          // console.log(rawCodes);

          var ascii = [];
          var saltAscii = 1;

          for(var i=0; i<rawCodes.length; i++) {
            ascii.push(null);
            ascii[i] = rawCodes[i].charCodeAt(0);
            saltAscii *= ascii[i];
          }

          // console.log(ascii);
          // console.log('saltAscii: ' + saltAscii);

          // generate salt, a-z
          saltAscii %= 26;
          // console.log(saltAscii);
          saltAscii += 97;
          saltAscii = Math.min(saltAscii, 122);
          saltAscii = Math.max(saltAscii, 97);

          var computedSalt = String.fromCharCode(saltAscii);
          // console.log('salt: ' + salt);

          if (computedSalt == salt) {
            console.log('    + Salt matched');
          }
          else {
            hasInvalidCondition = true;
            console.log('    + Salt mismatched');
          }
        }
        else {
          hasInvalidCondition = true;
          console.log('    + Invalid voucher code prefix');
        }
      }

      if (hasInvalidCondition) {
        return false;
      }
    }
  }
  return true;

}

// Functions to calculate discounted price
function getDiscountedObject__REWARD_TYPE__AMOUNT_DISCOUNT(objReward, objOption) {

  if(objReward.amount !== undefined) {

    var discountAmount = Number(objReward.amount);

    var discountedObject = initDiscountedObject();

    for(var j=0; j<objReward.items.length; j++) {

      switch(objReward.items[j].type) {

        case REWARD_ITEM_TYPE__ALL_PRODUCTS:
        console.log('    + reward item type = ' + REWARD_ITEM_TYPE__ALL_PRODUCTS);
          discountedObject.discountedOptionPrice = calculateDiscountedPrice(objOption.optionPrice, discountAmount, false);
          return discountedObject;
          break;
      }
    }
  }
  return null;
}

// Functions to calculate discounted price
function getDiscountedObject__REWARD_TYPE__PERCENT_DISCOUNT(objReward, jsonProducts, jsonBagItems, objOption) {

  if(objReward.amount !== undefined) {

    var discountPercentage = Number(objReward.amount);

    var discountedObject = initDiscountedObject();

    for(var j=0; j<objReward.items.length; j++) {

      switch(objReward.items[j].type) {

        case REWARD_ITEM_TYPE__PRODUCT_SKU:
          console.log('    + reward item type = ' + REWARD_ITEM_TYPE__PRODUCT_SKU);
          var shortCode = getOptionProductShortCode(objOption.optionID);
          var optionUnitQty = getOptionProductUnitQty(objOption.optionID);
          // console.log('-----');
          // console.log(objReward.items[j].values.productShortCode);
          // console.log(shortCode);
          if(objReward.items[j].values.productShortCode == shortCode) {

            // console.log(objOption);
            if (objReward.items[j].values.terms !== undefined) {

              if (objReward.items[j].values.terms.maxQty !== undefined) {
                discountedObject.discountedOptionPrice = calculateDiscountedPrice(objOption.optionPrice, discountPercentage);
                discountedObject.maxQty = parseInt(objReward.items[j].values.terms.maxQty);
                return discountedObject;
              }

              else if (objReward.items[j].values.terms.maxUnitQty !== undefined) {
                var maxUnitQty = parseInt(objReward.items[j].values.terms.maxUnitQty);
                var optionUnitPrice = objOption.optionPrice/optionUnitQty;
                var appliedDiscountQty = Math.min(maxUnitQty, optionUnitQty);
                var discountedUnitPrice = calculateDiscountedPrice(optionUnitPrice, discountPercentage);
                // var offsetPrice = discountedUnitPrice * appliedDiscountQty;

                // discountedObject.discountedOptionPrice = Math.round((objOption.optionPrice-offsetPrice)*100)/100
                discountedObject.discountedUnitPrice = discountedUnitPrice;
                discountedObject.maxUnitQty = maxUnitQty;
                discountedObject.optionUnitPrice = optionUnitPrice;
                discountedObject.optionUnitQty = optionUnitQty;
                return discountedObject;
              }
            }
          }
          break;


        case REWARD_ITEM_TYPE__PRODUCT_OPTION:
          console.log('    + reward item type = ' + REWARD_ITEM_TYPE__PRODUCT_OPTION);
          if(objReward.items[j].values.optionID == objOption.optionID) {
            discountedObject.discountedOptionPrice = calculateDiscountedPrice(objOption.optionPrice, discountPercentage);

            if (objReward.items[j].values.terms !== undefined
                && objReward.items[j].values.terms.maxQty !== undefined) {

              discountedObject.maxQty = parseInt(objReward.items[j].values.terms.maxQty);
              return discountedObject;
            }
          }
          break;


        case REWARD_ITEM_TYPE__ALL_PRODUCTS:
          console.log('    + reward item type = ' + REWARD_ITEM_TYPE__ALL_PRODUCTS);

          //here
          if (objReward.items[j].values != undefined
              && objReward.items[j].values.terms != undefined) {
            var terms = objReward.items[j].values.terms;

            if (terms.type == REWARD_ITEMS_TERMS__WITH_SERIES_PURCHASE) {
              //check if bag items contains at least one sku from series
              var seriesName = terms.seriesName;
              var hasSeries = false;

              for(var i=jsonBagItems.length-1; i>=0; i--) {

                var shortCode = jsonBagItems[i].optionID.split('-')[1];

                if (jsonProducts[shortCode].series.name == seriesName) {
                  hasSeries = true;
                  break;
                }
              }

              if(!hasSeries) {
                return null;
              }
            }
          }

          discountedObject.discountedOptionPrice = calculateDiscountedPrice(objOption.optionPrice, discountPercentage);
          if (objReward.remarks != undefined)
            discountedObject.remarks = objReward.remarks;
          return discountedObject;
          break;

      }
    }
  }
  return null;
}

// Functions to add free bag items
function getDiscountedObject__REWARD_TYPE__FREE_BAG_ITEMS(objReward, jsonProducts, jsonBagItems, objOption) {

  var discountedObject = initDiscountedObject();

  for(var j=0; j<objReward.items.length; j++) {

    switch(objReward.items[j].type) {

      case REWARD_ITEM_TYPE__PRODUCT_OPTION:
        console.log('    + reward item type = ' + REWARD_ITEM_TYPE__PRODUCT_OPTION);

        var rewardOptionID = objReward.items[j].values.optionID;
        var terms = objReward.items[j].values.terms;

        // get Qty
        if (terms.type == REWARD_ITEMS_TERMS__WITH_OPTION_PURCHASE_SUBSEQUENT) {

          // console.log('=============objOption');
          // console.log(objOption.optionID);

          if (objOption.optionID != rewardOptionID)
            return null;

          for (var x=0; x<jsonBagItems.length; x++) {

            if (jsonBagItems[x].optionID == rewardOptionID) {
              var qty = 0;
              var purchaseQty = parseInt(jsonBagItems[x].qty);
              // console.log('purchaseQty: ' + purchaseQty);
              //
              // console.log(terms.values.minUnitQty);
              // console.log(terms.values.subsequentUnitQty);
              // console.log(terms.values.unitQty);
              if (purchaseQty == terms.values.minUnitQty) {
                qty = terms.values.unitQty;
              }
              else {
                qty = terms.values.unitQty * (Math.floor((purchaseQty - terms.values.minUnitQty) / terms.values.subsequentUnitQty)+1);
              }

              // console.log('qty: ' + qty);

              var freeBagItem = {};
              freeBagItem.optionID = rewardOptionID;
              freeBagItem.qty = qty;

              discountedObject.freeBagItems = [];
              discountedObject.freeBagItems.push(freeBagItem)
            }
          }
        }
        break;
    }
  }

  return discountedObject;
}

function getDiscountedObject__SERVER_PROMO_CODE__NEWSLETTER(promoCode, objOption) {

  var discountedObject = initDiscountedObject();

  if (promoCode.toString().toUpperCase() == 'DOKIJUICE10') {
    var discountFactor = 1-(10/100);
    discountedObject.discountedOptionPrice = Math.round(Number(objOption.optionPrice) * discountFactor * 100)/100;
    return discountedObject;
  }
  return null;
}


function calculateDiscountedPrice(optionPrice, discount, isPercentageDiscount=true) {

  if (isPercentageDiscount) {
    var discountFactor = 1-(discount/100);
    return Math.round(Number(optionPrice) * discountFactor * 100)/100;
  }
  else {
    return Math.round((Number(optionPrice)-discount)*100)/100;
  }
}

function getOptionProductShortCode(optionID) {

  var ids = optionID.split('-');

  if (ids[0] == 'op') {
    return ids[1];
  }
  return null;
}

function getOptionProductUnitQty(optionID) {

  var ids = optionID.split('-');

  if (ids[0] == 'op') {
    return parseInt(ids[2].slice(0,-1));
  }
  return null;
}

function initDiscountedObject() {
  var discountedObject = {
    discountedOptionPrice: null,
    maxQty: null,
    discountedUnitPrice: null,
    maxUnitQty: null,
    optionUnitPrice: null,
    optionUnitQty: null,
    freeBagItems: null
  }

  return discountedObject;
}
