import jwt from 'jsonwebtoken';

export const AUTH = 'auth';
export const GET_JWT_TOKEN = 'getJWTToken';

export const state = () => ({
  isOpen: false,
});

export const mutations = {};

export const actions = {
  [GET_JWT_TOKEN]() {
    return jwt.sign(
      {},
      process.env.JWT_SECRET,
      { expiresIn: '1m' },
    );
  },
};
