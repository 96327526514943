import $ from 'jquery';
import Cookies from 'js-cookie';
import UtilCampaign from 'doki-js/util-campaign';
import DataSeries from 'doki-js/data/data-series';
import {format, fromUnixTime} from 'date-fns';

export default {

  getProduct(jsonProducts, jsonRecipe, productShortCode) {
    return getProduct(jsonProducts, jsonRecipe, productShortCode);
  },

  getCurrencyCode() {
    return 'MYR';
  },

  getProductsForFitnessSeries(jsonProducts, jsonCampaign, timestamp) {

    return getProductsInSeries(jsonProducts, jsonCampaign, timestamp, 'Fitness');
  },

  getProductsForEnergisingSeries(jsonProducts, jsonCampaign, timestamp) {

    return getProductsInSeries(jsonProducts, jsonCampaign, timestamp, 'Energising');
  },

  getProductsForCalorieBurningSeries(jsonProducts, jsonCampaign, timestamp) {

    return getProductsInSeries(jsonProducts, jsonCampaign, timestamp, 'Calorie Burning');
  },

  getProductsForCollagenSeries(jsonProducts, jsonCampaign, timestamp) {

    return getProductsInSeries(jsonProducts, jsonCampaign, timestamp, 'Collagen');
  },

  getProductsForCollagenAntiPhotoagingSeries(jsonProducts, jsonCampaign, timestamp) {

    return getProductsInSeries(jsonProducts, jsonCampaign, timestamp, 'Collagen Anti-Photoaging');
  },

  getProductsForSuperfoodSeries(jsonProducts, jsonCampaign, timestamp) {

    return getProductsInSeries(jsonProducts, jsonCampaign, timestamp, 'Superfood');
  },

  getProductsForGiftSeries(jsonGiftCategories, jsonProducts, jsonCampaign, timestamp) {

    var giftProducts = getProductsInSeries(jsonProducts, jsonCampaign, timestamp, 'Gift').products;
    var products = [];

    for (var i=0; i<jsonGiftCategories.length; i++) {

      products.push([]);
      
      var categoryID = jsonGiftCategories[i].id;
      
      for (var j=0; j<giftProducts.length; j++) {

        if(giftProducts[j].gift.categories !== undefined) {

          for (var x=0; x<giftProducts[j].gift.categories.length; x++) {
            
            if(giftProducts[j].gift.categories[x] == categoryID) {
              products[i].push(giftProducts[j]);
            }
          }
        }
      }
    }
    
    return {giftCategoryProducts: products};
  },

  getProductsForOthersBought(productShortCode, jsonProducts, jsonCampaign, timestamp) {

    var products = [];

    for(var i=0; i<jsonProducts[productShortCode].othersBought.length; i++) {

      var objCampaignDisplay = UtilCampaign.getDisplaysForProductListItem(
                                  jsonProducts[productShortCode].othersBought[i],
                                  jsonCampaign,
                                  timestamp);

      var objProduct = $.extend({},
                      jsonProducts[jsonProducts[productShortCode].othersBought[i]],
                      objCampaignDisplay);

      products.push(objProduct);
    }

    return {othersBoughtProducts: products};
  },

  getProductsForTrending(jsonTrending, jsonProducts, jsonCampaign, timestamp) {

    var products = [];

    for(var i=0; i<jsonTrending.trendingProductShortCodes.length; i++) {

      var objCampaignDisplay = UtilCampaign.getDisplaysForProductListItem(
                                  jsonTrending.trendingProductShortCodes[i],
                                  jsonCampaign,
                                  timestamp);

      var objProduct = $.extend({},
                      jsonProducts[jsonTrending.trendingProductShortCodes[i]],
                      objCampaignDisplay);

      products.push(objProduct);
      // console.log(objProduct);
    }

    return {recommendedProductsTrending: products};
  },

  getProductsForRecentlyViewed(jsonProducts, jsonCampaign, timestamp, productShortCode='') {

    var cookieViewedProducts = [];
    if (Cookies.get('viewedProducts') !== undefined && Cookies.get('viewedProducts') !== null) {
      cookieViewedProducts = Cookies.get('viewedProducts').split(",");
    }

    var viewedProducts = [];
    for(var i=0; i<cookieViewedProducts.length; i++) {
      if(cookieViewedProducts[i] != productShortCode) {
        viewedProducts.push(cookieViewedProducts[i]);
      }
    }

    var products = [];
    for(var i=0; i<viewedProducts.length; i++) {

      var objCampaignDisplay = UtilCampaign.getDisplaysForProductListItem(
                                  viewedProducts[i],
                                  jsonCampaign,
                                  timestamp);

      var objProduct = $.extend({},
                          jsonProducts[viewedProducts[i]],
                          objCampaignDisplay);

      products.push(objProduct);
    }

    return {recommendedProductsRecentlyViewed: products};
  },

  getProductNewReviews(productShortCode, jsonReview, jsonProductTarget) {

    var seriesName = jsonProductTarget.series.name;

    var obj = {
      totalReviewCount: jsonReview[seriesName].totalReviewCount,
      totalRating: jsonReview[seriesName].totalReviewCount,
      averageRating: jsonReview[seriesName].averageRating,
      totalRecommendCount: jsonReview[seriesName].totalRecommendCount,
      reviews: []
    };

    var dict = {}; //store review id that has been added to the top of the array beacuse it has matchign product code in product string

    
    var seriesReviews = jsonReview[seriesName].reviews

    // first loop: copy over reviews that have matching product
    for(var i=0; i<seriesReviews.length; i++) {

      if(seriesReviews[i].product.indexOf(productShortCode) != -1) { //put matching product review at the top
        
        var reviewID = seriesReviews[i].id;
        dict[reviewID] = true;

        obj.reviews.push( seriesReviews[i] );
      }
    }

    // second loop: copy over the remaining reviews
    for(var i=0; i<seriesReviews.length; i++) {

      var reviewID = seriesReviews[i].id;

      if(dict[reviewID] == undefined) {
        obj.reviews.push( seriesReviews[i] );
      }
    }

    return {newReview: obj};
  },

  getProductReviews(productShortCode, jsonReview) {

    var reviews = [];

    if (jsonReview[productShortCode] !== undefined) {

      var productReviews = jsonReview[productShortCode].reviews;

      for (var i=0; i<productReviews.length; i++) {

        var prettyDate = null;

        if (productReviews[i].timestamp !== undefined && productReviews[i].timestamp !== null) {

          try {
            prettyDate = format(fromUnixTime(productReviews[i].timestamp), 'MMM dd, yyyy');
          }
          catch(e) {
            console.log(e);
          }
        }

        var objReview = $.extend( {prettyDate: prettyDate},
                                  productReviews[i]);

        reviews.push(objReview);
      }
    }
    return {reviews: reviews};
  },

  getProductOptionItem(jsonProduct, optionID) {

    for(var i =0; i<jsonProduct.purchaseOptions.length; i++) {

      if (jsonProduct.purchaseOptions[i].optionID == optionID) {
        return jsonProduct.purchaseOptions[i];
      }
    }
    return null;
  },

  getBundleProductCodes(code) {

    if(code == 'bbsk') {
      return ['bb','ss','dg','pf','cg','tp','mg'];
    }
    else if(code == 'bbsb') {
      return ['bb','ss','dg','pf','cg','tp','mg'];
    }
    else if(code == 'bbeg') {
      return ['ss','cg','tp','mg','lg','pd'];
    }
    else if(code == 'bbsm') {
      return ['ss','pf'];
    }
    else if(code == 'bbgt') {
      return ['cg','tp'];
    }
    else if(code == 'bbff') {
      return ['ss','dg'];
    }
    else if(code == 'bbls') {
      return ['bb','mg'];
    }
    else if(code == 'bgsk') {
      return ['lg','mp','pd'];
    }
    else if(code == 'bggb') {
      return ['lg','mp','pd'];
    }
    else if(code == 'bgpg') {
      return ['lg','mp','pd','ss','pf'];
    }
  }
}

function getProduct(jsonProducts, jsonRecipe, productShortCode) {

  var seriesData = DataSeries.getData();

  var obj = jsonProducts[productShortCode];

  if(obj == undefined)
    return obj;

  var seriesName = obj.series.name;
  var seriesObj = seriesData[seriesName];

  //merge feature items of series and product (series first)
  if(obj.feature.desc.isUseSeries != undefined && obj.feature.desc.isUseSeries == true) {
    
    obj.feature.desc.items = seriesObj.feature.desc.items.concat(obj.feature.desc.items);
  }

  //merge product details sub items of series and product (series first)
  if(obj.productDetails.desc.isUseSeries != undefined && obj.productDetails.desc.isUseSeries == true) {
    
    obj.productDetails.desc.items = seriesObj.productDetails.desc.items.concat(obj.productDetails.desc.items);
  }

  if(obj.productDetails.targetCustomer.isUseSeries != undefined && obj.productDetails.targetCustomer.isUseSeries == true) {
    
    obj.productDetails.targetCustomer.items = seriesObj.productDetails.targetCustomer.items.concat(obj.productDetails.targetCustomer.items);
  }

  if(obj.productDetails.keyIngredient.isUseSeries != undefined && obj.productDetails.keyIngredient.isUseSeries == true) {
    
    obj.productDetails.keyIngredient.items = seriesObj.productDetails.keyIngredient.items.concat(obj.productDetails.keyIngredient.items);
  }

  // get recipe items
  var recipes = [];

  for(var i=0; i<obj.recipe.items.length; i++) {
    var recipeID = obj.recipe.items[i];

    var recipe = jsonRecipe[recipeID];

    if(recipe != undefined) {
      recipes.push(recipe);
    }
  }

  //merge product details sub items of series and product (series first)
  if(obj.recipe.isUseSeries != undefined && obj.recipe.isUseSeries == true && seriesObj != undefined) {
    
    recipes = seriesObj.recipe.items.concat(recipes);
  }

  obj.recipe.items = recipes;

  //get faq
  if(seriesObj != undefined) {
    obj.faq = seriesObj.faq;
    obj.ctaWhisper = seriesObj.ctaWhisper;
  }
  else {
    obj.faq = {
      items: []
    };
  }

  //get science
  if( seriesObj != undefined &&
      obj.feature.science == undefined && //feature.science is not defined in product, then user series
      seriesObj.feature.science != undefined) {

    obj.feature.science = seriesObj.feature.science;
  }

  return obj;
}


function getProductsInSeries(jsonProducts, jsonCampaign, timestamp, seriesType) {

  var products = [];

  var seriesName = null;
  
  Object.keys(jsonProducts).forEach(function(key,index) {
    // console.log(jsonProducts[key]);

    if(jsonProducts[key].series !== undefined && jsonProducts[key].productShortCode !== undefined) {

      if(jsonProducts[key].series.type == seriesType) {

        if(seriesName == null)
          seriesName = jsonProducts[key].series.name;

        var objCampaignDisplay = UtilCampaign.getDisplaysForProductListItem(
                                    jsonProducts[key].productShortCode,
                                    jsonCampaign,
                                    timestamp);

        var objProduct = $.extend({},
                        jsonProducts[key],
                        objCampaignDisplay);

        products.push(objProduct);
        // console.log(objProduct);
      }
    }
  });

  var seriesData = DataSeries.getData();
  var seriesObj = seriesData[seriesName];

  return {
    products: products,
    faq: seriesObj.faq
  };
}
