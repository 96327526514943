import $ from 'jquery';
import {format, fromUnixTime} from 'date-fns';
import UtilProduct from 'doki-js/util-product';
import UtilCampaign from 'doki-js/util-campaign';
import Cookies from 'js-cookie';
import axios from 'axios';

const MIN_SUBTOTAL_FOR_FREE_DELIVERY = 150;

export default {

  replaceAbandonCartOrderIDInCookie() { //not used
    hasOrderIDInURL();
  },

  removeAbandonCartOrderIDInCookie() {
    Cookies.remove('abandonCartOrderID');
  },
  
  sendAbandonBagNotification(objBag, objDelivery, isSubscribe, orderID, forceTrigger=false) {

    var bagObj = objBag.bag;
    var deliveryObj = objDelivery.delivery;

    // Checks here:
    if (!forceTrigger) {
      // 0. objBag has items, subtotal > 0; objDelivery has name and email
      if (bagObj.bagItems.length <=0
          || bagObj.subTotalPrice <= 0
          || deliveryObj.contact == undefined
          || deliveryObj.contact.email == ''
          || deliveryObj.contact.name == '') {

        console.log('0. No bga items or subTotal = 0; no email or customer name');
        return;
      }

      // 1. URL doesn't contain order id
      if (hasOrderIDInURL()) {
        console.log('1. No trigger: has order id in url');
        return;
      }

      // 2. Check cache for existing entry
      if ( Cookies.get('abandonCartOrderID') !== undefined &&
           Cookies.get('abandonCartOrderID') !== null &&
           Cookies.get('abandonCartOrderID') == orderID)
      {
        console.log('2. No trigger: abandon cart order id exist in cookie');
        return;
      }
    }

    var host = "https://hooks.zapier.com/hooks/catch/7961202/bie3hhr/";

    var mcBagObj = getMailChimpBagObject(bagObj, orderID);

    var obj = {
      id: orderID,
      customer: {
        id: orderID,
        first_name: deliveryObj.contact.name,
        email_address: deliveryObj.contact.email,
        opt_in_status: isSubscribe
      },
      currency_code: "SGD",
      order_total: mcBagObj.subTotal,
      lines: mcBagObj.items,
      checkout_url: "https://www.dokijuice.com.my/summary/" + orderID + "?utm_source=cart&utm_medium=email&utm_campaign=fromcart"
    };
    console.log(obj);

    // var obj2 = {
    //   "id":orderID,
    //   "customer": {
    //     "id": orderID,
    //     "first_name":"Jasmine",
    //     "email_address": "jasmine@dokijuice.com.my",
    //     "opt_in_status": true
    //   },
    //   "currency_code":"SGD",
    //   "order_total":100,
    //   "lines": [
    //     {
    //     "id": "Passion Dragon",
    //     "product_id": "pd",
    //     "product_variant_id": "op-pd-1b",
    //     "quantity": 3,
    //     "price": 26.7
    //     }
    //   ],
    //   "checkout_url":"https://staging.dokijuice.com.my/summary/"+orderID
    // }

    var data = JSON.stringify(obj);

    axios.post(host, data)
      .then(function(res){
        // console.log('*********');
        // console.log(res);
        console.log('3. Trigger: Zap sent');
      })
      .catch(function(e) {
        console.log(e);
      });

    // Update in cache
    Cookies.set('abandonCartOrderID', orderID);
    console.log('4. Add abandon cart order id to cookie');

    // 2. clear cache in order confirmation page

  },

  updateShareRewardInBagObject(objBag, jsonShareRewardBagItems, jsonProducts) {

    if(jsonShareRewardBagItems == undefined)
      return;

    var obj = objBag.bag;
    obj.shareRewardItems = []; //always reset

    for(var i=jsonShareRewardBagItems.length-1; i>=0; i--) {

      var item = jsonShareRewardBagItems[i];
      var optionID = item.optionID;
      var qty = parseInt(item.qty);
      var objBagItem = getBagItemObject(optionID, qty, jsonProducts);
      objBagItem.discountedItemPrice = 0;
      objBagItem.itemPriceSaved = objBagItem.itemPrice;

      var shareRewardItem = $.extend( {},
                                item,
                                objBagItem);

      obj.shareRewardItems.push(shareRewardItem);

      // Update bag object
      obj.totalQty += qty;
      obj.subTotalOriginalPrice += objBagItem.originalItemPrice;
      // obj.subTotalPrice no change
      obj.subTotalPriceSaved += objBagItem.itemPriceSaved;
    }
  },

  getBagObject(jsonBagItems, jsonProducts, jsonCampaigns, timestamp, jsonStockStatuses, jsonStockCount, jsonGift=null, isDeliveryToEastMalaysia=false, promoCode='', isServerPromoCode=false, isValidServerPromoCode=false) {

    var obj = {
      subTotalOriginalPrice: 0,
      subTotalPrice: 0,
      subTotalPriceSaved: 0,
      totalQty: 0,
      isDeliveryToEastMalaysia: false,
      deliveryFee: 0,
      totalPrice: 0,
      bagItems: [],
      totalBoxCount: 0,
      campaign: {
        promoCode: '',
        isCodeValid: false,
        discountedAmount: 0,
        freeBagItem: null,
        freeGift: null
      }
    };

    // Get initial subTotalPrice (for applying campaigns that rely on subtotal )
    var initialSubTotalPrice = calculateBagInitalSubTotalPrice(jsonBagItems, jsonProducts);

    var includedSendGiftOption = null;
    var discountedUnitCounts = {};  // for tracking how many units has been discounted for promo discount on unit
    var optionFreeBagItems = [];

    for(var i=jsonBagItems.length-1; i>=0; i--) {  //display in chronological order

      var optionID = jsonBagItems[i].optionID;
      var qty = parseInt(jsonBagItems[i].qty);
      var objBagItem = getBagItemObject(optionID, qty, jsonProducts);

      //
      // Get Discounted Option Object
      //
      console.log('+++++++++++++++UTIL-CHECKOUT');
      console.log('  Processing getDiscountedOptionObject');
      var discountedObj = UtilCampaign.getDiscountedOptionObject(jsonCampaigns, jsonProducts, jsonBagItems, objBagItem.option, initialSubTotalPrice, timestamp, promoCode, isServerPromoCode, isValidServerPromoCode);
      console.log('  Result: discountedOptionObj = ...');
      console.log(discountedObj);

      var productShortCode = optionID.split('-')[1];
      if (discountedUnitCounts[productShortCode] == undefined) {
        discountedUnitCounts[productShortCode] = {
          count: 0
        };
      }

      if (discountedObj !== null
          && (discountedObj.discountedOptionPrice !== null || discountedObj.freeBagItems !== null)) {

        // percent/amount discount
        if (discountedObj.freeBagItems == null ) {

          if (discountedObj.maxQty !== undefined && discountedObj.maxQty !== null) {
            objBagItem.discountedItemPrice = 0;

          for(var j=0; j<qty; j++) {

            if (j<discountedObj.maxQty) {
              objBagItem.discountedItemPrice += discountedObj.discountedOptionPrice;
            }
            else {
              objBagItem.discountedItemPrice += optionPrice;
            }
          }
        }
        else if (discountedObj.maxUnitQty !== undefined && discountedObj.maxUnitQty !== null ) {

          objBagItem.discountedItemPrice = 0;
          var totalUnitQty = qty * discountedObj.optionUnitQty;

          for(var j=0; j<totalUnitQty; j++) {

            if ( j<discountedObj.maxUnitQty &&
                 discountedUnitCounts[productShortCode].count < discountedObj.maxUnitQty ) {

              objBagItem.discountedItemPrice += discountedObj.discountedUnitPrice;

              discountedUnitCounts[productShortCode].count++;
            }
            else {
              objBagItem.discountedItemPrice += discountedObj.optionUnitPrice;
            }
          }

        }
        else {
          objBagItem.discountedItemPrice = qty * discountedObj.discountedOptionPrice;
        }
        objBagItem.itemPriceSaved = objBagItem.originalItemPrice - objBagItem.discountedItemPrice;
      }

      // free bag items
      else {
        objBagItem.itemPriceSaved = objBagItem.originalItemPrice - objBagItem.itemPrice;

        for(var x=0; x<discountedObj.freeBagItems.length; x++) {
          optionFreeBagItems.push(discountedObj.freeBagItems[x]);
        }
      }

    }
    else { //invalid condition
      objBagItem.itemPriceSaved = objBagItem.originalItemPrice - objBagItem.itemPrice;
    }

    // Update bag object
    obj.totalQty += qty;
    obj.subTotalOriginalPrice += objBagItem.originalItemPrice;
    obj.subTotalPrice += ((objBagItem.discountedItemPrice !== null) ? objBagItem.discountedItemPrice : objBagItem.itemPrice);
    obj.subTotalPriceSaved += objBagItem.itemPriceSaved;
    obj.campaign.discountedAmount += ((objBagItem.discountedItemPrice !== null) ? (objBagItem.itemPrice-objBagItem.discountedItemPrice) : 0);

    console.log('obj.subTotalPriceSaved: ' + obj.subTotalPriceSaved);


    if (discountedObj !== null && discountedObj.remarks != undefined)
      obj.campaign.remarks = discountedObj.remarks;

    var item = $.extend({},
                jsonBagItems[i],
                objBagItem,
                jsonStockStatuses[productShortCode],
                jsonStockCount[productShortCode]);
    obj.bagItems.push(item);

      // Get box count for each bag item x qty
      var boxCount = parseInt(optionID.split('-')[2].split('b')[0]) * qty;
      obj.totalBoxCount += boxCount;

      // Check if there's send gift in included in bag item
      if (includedSendGiftOption == null) {
        if (objBagItem.product.sendGiftOption !== undefined && objBagItem.product.sendGiftOption !== '') {
          includedSendGiftOption = objBagItem.product.sendGiftOption;
        }
      }
    }

    // Option free bag items
    if (optionFreeBagItems.length > 0) {
    // if (false) {

      var freeBagItemsTotalAmount = 0;

      for(var i=0; i<optionFreeBagItems.length; i++) {

        var freeBagItem = optionFreeBagItems[i];
        var objBagItem = getBagItemObject(freeBagItem.optionID, freeBagItem.qty, jsonProducts, true);
        objBagItem.discountedItemPrice = 0;
        objBagItem.itemPriceSaved = objBagItem.itemPrice;

        // var productShortCode = optionID.split('-')[1];
        // var objProduct = jsonProducts[productShortCode];

        var item = $.extend({},
                      freeBagItem,
                      objBagItem);

        obj.bagItems.push(item);

        var freeBagItemsAmount = (objBagItem.discountedItemPrice !== null) ? (objBagItem.itemPrice-objBagItem.discountedItemPrice) : 0;

        // Update bag object
        obj.totalQty += freeBagItem.qty;
        obj.subTotalOriginalPrice += objBagItem.originalItemPrice;
        obj.subTotalPriceSaved += objBagItem.itemPriceSaved;
        obj.campaign.discountedAmount += freeBagItemsAmount;
        freeBagItemsTotalAmount += freeBagItemsAmount;
      }

      obj.campaign.freeBagItem = {};
      obj.campaign.freeBagItem.items = optionFreeBagItems;
      obj.campaign.freeBagItem.amount = freeBagItemsTotalAmount;
    }

    //
    // Get Discounted SubTotal Object
    //
    console.log('+++++++++++++++UTIL-CHECKOUT');
    console.log('  Processing getDiscountedSubTotalObject');
    var discountedSubTotalObj = UtilCampaign.getDiscountedSubTotalObject(jsonCampaigns, jsonProducts, jsonBagItems, obj.subTotalPrice, timestamp, promoCode, isServerPromoCode, isValidServerPromoCode);

    if (discountedSubTotalObj !== null) {

      // Discount on sub total
      if (discountedSubTotalObj.discountedSubTotalPrice !== undefined &&
          discountedSubTotalObj.discountedSubTotalPrice !== null) {

        obj.campaign.discountedAmount += (obj.subTotalPrice - discountedSubTotalObj.discountedSubTotalPrice);
        obj.subTotalPrice = discountedSubTotalObj.discountedSubTotalPrice;
        obj.subTotalPriceSaved += obj.campaign.discountedAmount;
        console.log('  Result: Revised campaign.discountedAmount = ' + obj.campaign.discountedAmount);
      }
      // Free bag items
      else if ( discountedSubTotalObj.freeBagItems !== undefined &&
                discountedSubTotalObj.freeBagItems !== null) {

        var freeBagItemsTotalAmount = 0;

        for(var i=0; i<discountedSubTotalObj.freeBagItems.length; i++) {  //display in chronological order

          var freeBagItem = discountedSubTotalObj.freeBagItems[i];
          var objBagItem = getBagItemObject(freeBagItem.optionID, freeBagItem.qty, jsonProducts, true);
          objBagItem.discountedItemPrice = 0;
          objBagItem.itemPriceSaved = objBagItem.itemPrice;

          // var productShortCode = optionID.split('-')[1];
          // var objProduct = jsonProducts[productShortCode];

          var item = $.extend({},
                        freeBagItem,
                        objBagItem);

          obj.bagItems.push(item);

          var freeBagItemsAmount = (objBagItem.discountedItemPrice !== null) ? (objBagItem.itemPrice-objBagItem.discountedItemPrice) : 0;

          // Update bag object
          obj.totalQty += freeBagItem.qty;
          obj.subTotalOriginalPrice += objBagItem.originalItemPrice;
          obj.subTotalPriceSaved += objBagItem.itemPriceSaved;
          obj.campaign.discountedAmount += freeBagItemsAmount;
          freeBagItemsTotalAmount += freeBagItemsAmount;
        }

        obj.campaign.freeBagItem = {};
        obj.campaign.freeBagItem.items = discountedSubTotalObj.freeBagItems;
        obj.campaign.freeBagItem.amount = freeBagItemsTotalAmount;

        if (discountedSubTotalObj.remarks != undefined)
          obj.campaign.remarks = discountedSubTotalObj.remarks;
      }
    }


    //
    // Get Discounted Box Count Object
    //
    console.log('+++++++++++++++UTIL-CHECKOUT');
    console.log('  Processing getDiscountedBoxCountObject');

    var discountedBoxCountObj = UtilCampaign.getDiscountedBoxCountObject(jsonCampaigns, obj.totalBoxCount, timestamp, promoCode, isServerPromoCode, isValidServerPromoCode);

    console.log(discountedBoxCountObj);

    if (discountedBoxCountObj !== null) {

      // Discount on sub total
      if (discountedBoxCountObj.discountedSubTotalPrice !== undefined &&
          discountedBoxCountObj.discountedSubTotalPrice !== null) {

        obj.campaign.discountedAmount += (obj.subTotalPrice - discountedBoxCountObj.discountedSubTotalPrice);
        obj.subTotalPrice = discountedBoxCountObj.discountedSubTotalPrice;
        obj.subTotalPriceSaved += obj.campaign.discountedAmount;
        console.log('  Result: Revised campaign.discountedAmount = ' + obj.campaign.discountedAmount);
      }
      // Free bag items
      else if ( discountedBoxCountObj.freeBagItems !== undefined &&
                discountedBoxCountObj.freeBagItems !== null) {

        var freeBagItemsTotalAmount = 0;

        for(var i=0; i<discountedBoxCountObj.freeBagItems.length; i++) {  //display in chronological order

          var freeBagItem = discountedBoxCountObj.freeBagItems[i];
          var objBagItem = getBagItemObject(freeBagItem.optionID, freeBagItem.qty, jsonProducts, true);
          objBagItem.discountedItemPrice = 0;
          objBagItem.itemPriceSaved = objBagItem.itemPrice;

          // var productShortCode = optionID.split('-')[1];
          // var objProduct = jsonProducts[productShortCode];

          var item = $.extend({},
                        freeBagItem,
                        objBagItem);

          obj.bagItems.push(item);

          var freeBagItemsAmount = (objBagItem.discountedItemPrice !== null) ? (objBagItem.itemPrice-objBagItem.discountedItemPrice) : 0;

          // Update bag object
          obj.totalQty += freeBagItem.qty;
          obj.subTotalOriginalPrice += objBagItem.originalItemPrice;
          obj.subTotalPriceSaved += objBagItem.itemPriceSaved;
          obj.campaign.discountedAmount += freeBagItemsAmount;
          freeBagItemsTotalAmount += freeBagItemsAmount;
        }

        obj.campaign.freeBagItem = {};
        obj.campaign.freeBagItem.items = discountedBoxCountObj.freeBagItems;
        obj.campaign.freeBagItem.amount = freeBagItemsTotalAmount;
      }
    }

    //
    // Get Free Gift Object
    //
    console.log('+++++++++++++++UTIL-CHECKOUT');
    console.log('  Processing getFreeGiftObject');
    obj.campaign.freeGift = UtilCampaign.getFreeGiftObject(jsonBagItems, jsonProducts, jsonCampaigns, timestamp, promoCode, initialSubTotalPrice);
    console.log('  Result: freeGiftObj = ...');
    console.log(obj.campaign.freeGift);

    if( obj.campaign.discountedAmount > 0 ||
        obj.campaign.freeGift != null ) {
      obj.campaign.isCodeValid = true;
    }
    else {
      obj.campaign.isCodeValid = false;
    }

    // console.log(obj.campaign);


    // send gift
    if (includedSendGiftOption != null) {
      var giftObj = {
        option: includedSendGiftOption,
        message: '',
        isIncluded: true
      }
      if (jsonGift!=null) {
        giftObj.message = jsonGift.message;
      }
      obj.gift = getGiftObject(giftObj);
    }
    else {
      obj.gift = getGiftObject(jsonGift);
    }

    if (obj.gift != undefined && obj.gift != null && obj.gift.optionPrice != undefined) {
      obj.totalPrice += Number(obj.gift.optionPrice);
    }


    // store promo code
    if (promoCode !== null && promoCode !== undefined && promoCode !== '') {
      obj.campaign.promoCode = promoCode.toString().toUpperCase();
    }


    //add addOnItems into obj, and update:
    //  - subTotalPrice
    //  - subTotalOriginalPrice
    //  - subTotalPrice
    //  - subTotalPriceSaved
    //  - totalBoxCount
    //  - totalPrice
    //  - totalQty
    var addOnItems = getBagAddOnItems(obj, jsonProducts);

    if (addOnItems != null) {

      for(var i=0; i<addOnItems.length; i++) {
        var item = addOnItems[i];

        obj.subTotalPrice += item.discountedItemPrice;
        obj.subTotalOriginalPrice += item.originalItemPrice;
        obj.subTotalPriceSaved += item.itemPriceSaved;
        obj.totalBoxCount += item.qty;
        obj.totalPrice += item.discountedItemPrice;
        obj.totalQty += item.qty;
      }
      obj.addOnItems = addOnItems;
    }

    //update delivery fee
    obj.deliveryFee = getDeliveryFee(obj.subTotalPrice);
    obj.totalPrice = obj.subTotalPrice + obj.deliveryFee;


    // console.log(obj);
    return {bag: obj};
  },

  removeAddOnItemsInCookies() { //to be called in Bag, Add On, Summary, Order-Confirmation page
    Cookies.remove('addOnItems');
  },

  hasAddOnItemsInCookies() {
    return hasAddOnItemsInCookies();
  },

  getAddOnItemsSubTotalInCookies() {

    var addOnObj = getAddOnObjInCookies();

    if (addOnObj !== null) {
      return addOnObj.subTotalPrice;
    }
    else {
      return null;
    }
  },

  getAddOnItemsQtyInCookies() {

    var qty = 0;
    var addOnObj = getAddOnObjInCookies();

    if (addOnObj !== null) {

      for(var i=0; i<addOnObj.items.length; i++) {

        var item = addOnObj.items[i];

        qty += item.qty;
      }
    }
    return qty;
  },

  getShareRewardDisplayObject(jsonProducts, jsonConfig, jsonStockStatuses) {
    return {shareReward: getShareRewardDisplayObject(jsonProducts, jsonConfig, jsonStockStatuses)};
  },

  getAddOnDisplayObject(bagObj, jsonAddOn, jsonProducts) {
    return {addOn: $.extend( {subTotalPrice: bagObj.bag.subTotalPrice},
                             getAddOnDisplayItemsObject(bagObj, jsonAddOn, jsonProducts))};
  },

  getDeliveryObject(jsonDelivery) {

    var obj = jsonDelivery;

    if (obj.deliveryDate.byTimestamp !== undefined) {

      var prettyByDate = format(fromUnixTime(obj.deliveryDate.byTimestamp), 'MMM dd, yyyy');

      obj.deliveryDate.prettyByDate = prettyByDate;
    }

    return {delivery: obj}
  },

  getOrderObject(jsonOrder) {

    var obj = jsonOrder;

    if (obj.timestamp !== undefined) {

      var prettyByDate = format(fromUnixTime(obj.timestamp), 'MMM dd, yyyy');

      obj.prettyDate = prettyByDate;
    }

    return {order: obj}
  },
}

function hasAddOnItemsInCookies() {

  var strAddOnItems = Cookies.get('addOnItems');

  if (strAddOnItems === undefined ||
      strAddOnItems === null ||
      strAddOnItems == '')
  {
    return false;
  }

  return true;
}

function getAddOnObjInCookies() {

  if (!hasAddOnItemsInCookies()) {
    return null;
  }

  var strAddOnItems = Cookies.get('addOnItems');
  var addOnObj = JSON.parse(strAddOnItems);

  return addOnObj;
}

function getBagAddOnItems(bag, jsonProducts) {

  var addOnItems = [];

  var addOnObj = getAddOnObjInCookies();

  if (addOnObj !== null) {

    // check is bag items has changed
    var hasBagItemsChangedBeforeSummary = false;

    if (bag.campaign.discountedAmount == 0) { //if no campaign discountedAmount
      hasBagItemsChangedBeforeSummary = !(bag.subTotalPrice == addOnObj.subTotalPrice); //then subtotalPrice should match
    }
    else {

      if (bag.campaign.freeBagItem !== null) { //if there's free bag item, then subtotal price will be the same as Coookie

        hasBagItemsChangedBeforeSummary = !(bag.subTotalPrice == addOnObj.subTotalPrice)
      }
      else { //if there's no free bag item, means it's a price discount then subtotal price will not be same

        var strSubTotalPriceBeforePromoDiscount = (bag.subTotalPrice + bag.campaign.discountedAmount).toFixed(2); //subTotalPriceSaved includes both store and promo code discount
        var strAddOnObjSubTotalPrice = addOnObj.subTotalPrice.toFixed(2);
        hasBagItemsChangedBeforeSummary = !(strSubTotalPriceBeforePromoDiscount == strAddOnObjSubTotalPrice);
      }
    }

    if (hasBagItemsChangedBeforeSummary) { //if current bag items is different from one when add on item was added
      Cookies.remove('addOnItems');
    }
    else {

      //add addOnItems to bag
      for(var i=0; i<addOnObj.items.length; i++) {

        var item = addOnObj.items[i];

        var objBagItem = getBagItemObject(
                            item.optionID,
                            item.qty,
                            jsonProducts, false, true);

        objBagItem.discountedItemPrice = item.addOnPrice;
        objBagItem.itemPriceSaved = objBagItem.itemPrice-item.addOnPrice;

        var addOnItem = $.extend( {},
                                  item,
                                  objBagItem);

        addOnItems.push(addOnItem);
      }
    }
  }

  return addOnItems;
}

function getShareRewardDisplayObject(jsonProducts, jsonConfig, jsonStockStatuses) {

  var shareRewardDisplayItems = [];

  var excludeProductCodesStr = ',' + jsonConfig.shareReward.excludeProductCodes.toString() + ',';

  Object.keys(jsonProducts).forEach(function(key) {

    var product = jsonProducts[key];

    if( product.productType == 'individual'
        && excludeProductCodesStr.indexOf(','+key+',') == -1
        && jsonStockStatuses[key].isInStock != null) {

      var objBagItem = getBagItemObject(  product.purchaseOptions[0].optionID,
                                          1,
                                          jsonProducts);

      objBagItem.optionID = product.purchaseOptions[0].optionID;
      objBagItem.qty = 1;
      objBagItem.discountedItemPrice = 0;

      var item = $.extend({},
                          objBagItem,
                          jsonStockStatuses[key]);

      shareRewardDisplayItems.push(item);
    }
  });

  return {shareRewardDisplayItems: shareRewardDisplayItems};
}

function getAddOnDisplayItemsObject(bagObj, jsonAddOn, jsonProducts) {

  var addOnDisplayItems = [];

  var bagItems = bagObj.bag.bagItems;
  var subTotalPrice = bagObj.bag.subTotalPrice;
  var hasProductSeriesName = {};

  // 0. construct an object like this: only product series in jsonAddOn.seriesNamePriority will be added to object
  /*
    var hasProductSeriesName = {
      "Glow": false,
      "Cadence": false,
      "Booster": false,
      "Lean Up": false,
      "Hype": false
    };
  */
  for(var i=0; i<jsonAddOn.seriesNamePriority.length; i++) {
    hasProductSeriesName[jsonAddOn.seriesNamePriority[i]] = false;
  }

  // 1. Check which series is not in bag
  var numSeriesNotInBag = jsonAddOn.seriesNamePriority.length;

  for(var i=0; i<bagItems.length; i++) {
    var bagItem = bagItems[i];
    var seriesName = bagItem.product.series.name;

    if (hasProductSeriesName[seriesName] != undefined
        && hasProductSeriesName[seriesName] == false) { //if series not yet found in bag items

      hasProductSeriesName[seriesName] = true; //set to true
      numSeriesNotInBag--;
    }
  }

  // 2. Get add on items list in order of series priority
  var tempItems = [];

  if (subTotalPrice >= jsonAddOn.minPurchase) {

    var lastItemIndex = -1;
    var lastItemPrice = 0;

    for(var i=0; i<jsonAddOn.seriesNamePriority.length; i++) {

      var seriesName = jsonAddOn.seriesNamePriority[i];

      if ( (numSeriesNotInBag == 0)
           ||
           (hasProductSeriesName[seriesName] == false && jsonAddOn.seriesItems[seriesName] !== undefined)
        ) { //if series is not in bag

        var seriesItems = jsonAddOn.seriesItems[seriesName];

        Object.keys(seriesItems).forEach(function(key,index) {

          var itemsCategory = seriesItems[key];

          if( itemsCategory.minPurchase == undefined
              || subTotalPrice >= itemsCategory.minPurchase) { //if subTotalPrice is more than series category (e.g. 1b, 2b, 3b) minPurchase

            if (itemsCategory.items.length > 0) { //ensure add-on items in json is not empty

              if (itemsCategory.selectionType == 'fixed') {

                tempItems.push(
                  {
                    optionID: itemsCategory.items[0].optionID,
                    qty: itemsCategory.qty,
                    addOnPrice: itemsCategory.addOnPrice,
                    addOnTags: itemsCategory.items[0].tags,
                    addOnDesc: itemsCategory.items[0].desc,
                    review: itemsCategory.items[0].review
                  });

                if ( tempItems.length > 2 && lastItemPrice < itemsCategory.addOnPrice) { //store the price and inex of the most expensive price item so far
                  lastItemPrice = itemsCategory.addOnPrice;
                  lastItemIndex = tempItems.length - 1;
                }
              }
            }
          }
        });
      }
    }

    // take the first 2 and most expensive item in the list; may not always be 3 items
    for(var i=0; i<tempItems.length; i++) {

      var addOnItem = tempItems[i];

      if ( i<2
           ||
           (lastItemIndex >= 2 && lastItemIndex == i) //add most expensive item as the 3rd item in list
         )
      {

        var objBagItem = getBagItemObject(
                          addOnItem.optionID,
                          addOnItem.qty,
                          jsonProducts, false, true);

        objBagItem.discountedItemPrice = addOnItem.addOnPrice;
        objBagItem.itemPriceSaved = objBagItem.itemPrice-addOnItem.addOnPrice;

        var item = $.extend({},
                      addOnItem,
                      objBagItem);

        addOnDisplayItems.push(item);
      }
    }
  }

  return {addOnDisplayItems: addOnDisplayItems};
}

function getMailChimpBagObject(objBag, orderID) {

  var obj = {
    subTotal: objBag.subTotalPrice,
    items: []
  }

  // console.log('objBag');
  // console.log(objBag);

  for (var i=0; i<objBag.bagItems.length; i++) {

    var bagItem = objBag.bagItems[i];
    var variantID;

    if (bagItem.optionID.split('-').length > 3) { //remove trailing '-exp'
      variantID = bagItem.optionID.substring(0,bagItem.optionID.indexOf('-exp'));
    }
    else {
      variantID = bagItem.optionID
    }
    var item = {
      id: orderID + '-' + i + '-' + bagItem.product.productShortCode,
      product_id: bagItem.product.productShortCode,
      product_variant_id: variantID,
      quantity: bagItem.qty,
      price: bagItem.option.optionPrice,
    };

    obj.items.push(item);
  }
  return obj;
}

function getBagItemObject(optionID, qty, jsonProducts, isMarkFree=false, isAddOn=false, addOnPrice=null) { //Note: addOnPrice is not unit price; it considers the qty

  var productShortCode = optionID.split('-')[1];
  var objProduct = jsonProducts[productShortCode];

  if (objProduct === undefined) {
    return null;
  }

  var objOption = UtilProduct.getProductOptionItem(objProduct, optionID);

  if(objOption.descs == undefined && objOption.descriptions != undefined) { //backward compatibility for orders that uses old json structure that uses "descriptions" instead of "descs"
    objOption.descs = objOption.descriptions;
  }

  var objBagItem = {
    itemPrice: ( (isAddOn==true && addOnPrice!=null) ? addOnPrice : (qty * Number(objOption.optionPrice)) ),
    originalItemPrice: qty * Number(objOption.originalOptionPrice),
    discountedItemPrice: null,
    itemPriceSaved: 0,
    option: objOption,
    product: objProduct,
    isFree: isMarkFree,
    isAddOn: isAddOn
  };

  return objBagItem;
}


function calculateBagInitalSubTotalPrice(jsonBagItems, jsonProducts) {
  var initialSubTotalPrice = 0;
  for(var i=jsonBagItems.length-1; i>=0; i--) {  //display in chronological order

    var optionID = jsonBagItems[i].optionID;
    var productShortCode = jsonBagItems[i].optionID.split('-')[1];
    var qty = parseInt(jsonBagItems[i].qty);
    var objProduct = jsonProducts[productShortCode];

    if (objProduct != undefined) {
      var objOption = UtilProduct.getProductOptionItem(objProduct, optionID);
      var optionPrice = Number(objOption.optionPrice);
      initialSubTotalPrice += qty * optionPrice;
    }
  }
  return initialSubTotalPrice;
}

function getDeliveryFee(subTotalPrice, isDeliveryToEastMalaysia) {

  if (isDeliveryToEastMalaysia)
  {
    return 20;
  }
  else {
    if (subTotalPrice > MIN_SUBTOTAL_FOR_FREE_DELIVERY) {
      return 0;
    }
    else {
      return 15;
    }
  }
}

function getGiftObject(jsonGift) {

  var obj = jsonGift;

  if (obj !== undefined && obj !== null && obj.option !== 'none') {

    if (obj.option == 'card') {
      obj.optionPrice = 1;
      obj.optionDesc = 'Card with personalized message';
      return obj
    }
    else if (obj.option == 'card+wrap') {
      obj.optionPrice = 3.5;
      obj.optionDesc = 'Gift wrapping and card';
      return obj
    }
    else if (obj.option == 'card+flower') {
      obj.optionPrice = 0;
      obj.optionDesc = "Mother's day flowers and card";
      return obj
    }
  }
  return null;
}


function hasOrderIDInURL() {
  var hasOrderIDInURL = false;
  var isProdEnv = window.location.host.includes('dokijuice.com.my');
  var orderID;

  if (isProdEnv) {
    if (window.location.pathname.split('/').length > 2) {
      orderID = window.location.pathname.split('/')[2];
      hasOrderIDInURL = true;
    }
  }
  else {
    var query = require('query-string').parse(location.search);
    if (query['order-id'] !== undefined) {
      orderID = query['order-id'];
      hasOrderIDInURL = true;
    }
  }

  if (hasOrderIDInURL) {
    console.log('0. Abandon cart ID in cache replaced by ID in url');
    // Cookies.remove('abandonCartOrderID');
    Cookies.set('abandonCartOrderID', orderID);
  }

  return hasOrderIDInURL;
}
