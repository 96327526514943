import { getHostName } from '../utils/utils';
import { BAGS, FETCH_BAG_FROM_LOCAL_STORAGE } from './bags';

export const PROCESS_ORDER_STRIPE_V3 = 'processOrderStripeV3';
export const PROCESS_ORDER = 'processOrder';
export const GET_ORDER_BY_INVOICE_ID = 'getOrderByInvoiceID';
export const GET_ORDER_BY_ORDER_ID = 'getOrderByOrderID';
export const GET_HAS_CUSTOMER_USED_PROMO_CODE_BY_EMAIL = 'getHasCustomerUsedPromoCodeByEmail';
export const SET_INVOICE_ID = 'setInvoiceID';
export const STORE_ORDER_PAYLOAD = 'setOrderPayload';
export const FETCH_ORDER_PAYLOAD_FROM_LOCAL_STORAGE = 'fetchOrderPayloadFromLocalStorage';
export const ORDER_PAYLOAD = 'orderPayload';
export const ORDERS = 'orders';
export const ORDER_ID = 'orderID';
export const SET_ORDER_ID = 'setOrderID';
export const STORE_ORDER_ID_TO_LOCAL_STORAGE = 'storeOrderIDToLocalStorage';
export const FETCH_ORDER_ID_FROM_LOCAL_STORAGE = 'fetchOrderIDFromLocalStorage';
export const SET_INITIAL_ORDER_ID = 'setInitialOrderID';
export const GET_ORDER_ID = 'getOrderID';
export const STORE_TEMPORARY_ORDER = 'storeTemporaryOrder';

export const state = () => ({
  orderID: null,
  invoiceID: null,
  orderPayload: null,
  temporaryOrder: null,
});

export const mutations = {
  [SET_ORDER_ID](state, orderID) {
    state.orderID = orderID;
  },
  [SET_INVOICE_ID](state, invoiceID) {
    state.invoiceID = invoiceID;
  },
  [STORE_ORDER_PAYLOAD](state, payload) {
    state.orderPayload = payload;
  },
  [STORE_TEMPORARY_ORDER](state, payload) {
    state.temporaryOrder = payload;
  },
};

export const actions = {
  async [STORE_TEMPORARY_ORDER]({ commit }, payload) {
    commit(STORE_TEMPORARY_ORDER, payload);
  },
  async [PROCESS_ORDER_STRIPE_V3](context, payload) {
    const { data } = await this.$axios.post(`${getHostName()}/api/process-order-stripe-v3/my`, payload);
    return data;
  },
  async [GET_ORDER_BY_INVOICE_ID](context, invoiceID) {
    const { data } = await this.$axios.get(`${getHostName()}/api/get-order-by-invoice-id/my/${invoiceID}`);
    return data;
  },
  async [GET_ORDER_BY_ORDER_ID](context, orderID) {
    const { data } = await this.$axios.get(`${getHostName()}/api/get-order-by-order-id/my/${orderID}`);
    return data;
  },
  async [GET_HAS_CUSTOMER_USED_PROMO_CODE_BY_EMAIL](context, email) {
    const { data } = await this.$axios.get(`${getHostName()}/api/get-has-customer-used-promo-code-by-email/my/${email}`);
    return data;
  },
  async [PROCESS_ORDER](context, payload) {
    const { data } = await this.$axios.post(`${getHostName()}/api/process-order/my`, payload);
    return data;
  },
  async [SET_INVOICE_ID]({ commit }, invoiceID) {
    return commit(SET_INVOICE_ID, invoiceID);
  },
  async [STORE_ORDER_PAYLOAD]({ commit }, payload) {
    localStorage.setItem(ORDER_PAYLOAD, JSON.stringify(payload));
  },
  async [FETCH_ORDER_PAYLOAD_FROM_LOCAL_STORAGE]() {
    const orderPayload = localStorage.getItem(ORDER_PAYLOAD);
    return orderPayload
      ? JSON.parse(orderPayload)
      : null;
  },
  /**
   * Note: New Stripe Payment
   */
  async [STORE_ORDER_ID_TO_LOCAL_STORAGE]({ commit }, orderID) {
    localStorage.setItem(ORDER_ID, orderID);
  },
  async [FETCH_ORDER_ID_FROM_LOCAL_STORAGE]() {
    const orderID = localStorage.getItem(ORDER_ID);
    return orderID
      ? localStorage.getItem(ORDER_ID)
      : null;
  },
  async [SET_ORDER_ID]({ commit, dispatch }, orderID) {
    await dispatch(STORE_ORDER_ID_TO_LOCAL_STORAGE, orderID);
    await commit(SET_ORDER_ID, orderID);
    return orderID;
  },
  async [SET_INITIAL_ORDER_ID]({ dispatch }) {
    const orderID = await dispatch(FETCH_ORDER_ID_FROM_LOCAL_STORAGE);
    if (orderID) dispatch(SET_ORDER_ID, orderID);
  },
};

export const getters = {
  [GET_ORDER_ID](state, getters) {
    return state.orderID;
  },
  getOrderInfo(state, getters) {
    const id = state.invoiceID;
    const status = 'Paid';
    return {
      id,
      status,
    };
  },
};
