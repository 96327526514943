export const GIFT = 'gift';
export const STORE_GIFT_OBJECT = 'storeGiftObject';
export const GIFT_OBJECT = 'storeGiftObject';
export const FETCH_GIFT_OBJECT_FROM_LOCAL_STORAGE = 'fetchGiftObjectFromLocalStorage';
export const SET_INITIAL_GIFT = 'setInitialGift';
export const SET_GIFT = 'setGift';

export const state = () => ({
  gift: null,
});

export const mutations = {
  [SET_GIFT](state, gift) {
    state.gift = gift;
  },
};

export const actions = {
  [FETCH_GIFT_OBJECT_FROM_LOCAL_STORAGE]() {
    const giftObject = localStorage.getItem(GIFT_OBJECT);
    return giftObject
      ? JSON.parse(giftObject)
      : null;
  },
  async [STORE_GIFT_OBJECT]({ commit, dispatch }, gift) {
    await localStorage.setItem(GIFT_OBJECT, JSON.stringify(gift));
    return commit(SET_GIFT, gift);
  },
  async [SET_INITIAL_GIFT]({ commit, dispatch }) {
    const gift = await dispatch(FETCH_GIFT_OBJECT_FROM_LOCAL_STORAGE);
    return commit(SET_GIFT, gift);
  },
};

export const getters = {
  getGift(state) {
    return state.gift;
  },
};
