export const STORE_ERROR_TO_LOCAL_STORAGE = 'storeErrorToLocalStorage';
export const GET_ERROR_FROM_LOCAL_STORAGE = 'getErrorFromLocalStorage';
export const ERROR = 'error';

export const state = () => ({});

export const mutations = {};

export const actions = {
  [STORE_ERROR_TO_LOCAL_STORAGE](context, error) {
    localStorage.setItem(ERROR, JSON.stringify(error));
  },
  [GET_ERROR_FROM_LOCAL_STORAGE]() {
    const error = localStorage.getItem(ERROR);
    return error
      ? JSON.parse(localStorage.getItem(ERROR))
      : null;
  },
};
