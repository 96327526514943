var obj =
{
  "Lean Up": {
    "feature": {
      "desc": {
        "items": [
          "Formulated with Lipid Metabolism Technology™",
          "Enhances the release of free fatty acids naturally",
          "Made with a clinically proven formula backed by multiple studies",
          "Naturally stimulates metabolic rate",
          "Longer lasting benefits",
          "Blended with natural fruits for great taste and benefits",
          "No added artificial concentrate or colouring",
          "Contains 25mg of naturally-occurring caffeine",
          "Halal and ISO22000 certified"
        ]
      },
      "science": {
        "Lean Up": "Learn about the science behind LEAN UP"
      }
    },
    "ctaWhisper": "8 out of 10 customers find Lean Up effective",
    "recipe": {
      "items": []
    },
    "productDetails": {
      "desc": {
        "items": [
          "LEAN UP is formulated with Lipid Metabolism Technology™ to burn away unhealthy and excess fat mass compiling inside our body naturally. It is made with a clinically proven formula to reduce the body's calories by increasing your metabolic rate with natural active compounds from ethically sourced Mediterranean Grapefruit and Brazilian Guarana."
        ]
      },
      "targetCustomer": {
        "items": [
          "Anyone above 20 looking for a healthier alternative to burning their calories safely and effectively in their daily routine",
          "Busy individuals looking for a functional, long-lasting and natural way to reduce excess fat mass in the body",
          "Anyone who is seeking a healthy way to start their lean up journey"
        ]
      },
      "keyIngredient":  {
        "items": [
          {
            "title": "LIPID METABOLISM TECHNOLOGY™",
            "desc": "It uses a citrus extract standardised in polyphenols to improve long-term body composition by helping to reduce the excess of fat mass through increasing the metabolic rate in our body."
          },
          {
            "title": "STEVIA",
            "desc": "Sweetened naturally with Stevia, mother nature's 0-calorie plant sweetener, documented to have medicinal purposes such as antimicrobial, antidiarrheal, anti-inflammatory, diuretic and immunomodulatory actions."
          }
        ]
      }
    },
    "faq": {
      "items": [
        {
          "qn": "How does it help to keep my body lean?",
          "ans": [
            "LEAN UP uses natural bioactive polyphenols from Mediterranean Citrus and Brazilian Guarana to boost your metabolic rate resulting in up to 180kcal resting expenditure per day based on clinical studies.",
            "LEAN UP does not suppress your appetite and do not have laxative effect."
          ]
        },
        {
          "qn": "How long will it take for results to be shown?",
          "ans": [
            "The clinical results for our formula show up to -5.4kg weight loss in 12 weeks and a 10% increase in resting energy expenditure in 16 weeks which accelerates the calorie-burning process in the body. However, the results should be gradual throughout the consumption period and vary on each individual's lifestyle and habits.",
            "We recommend pairing LEAN UP with a healthy and active lifestyle."
          ]
        },
        {
          "qn": "Is there any side effect?",
          "ans": [
            "LEAN UP's active compounds are bioactive polyphenols derived from plants and fruits, hence there shouldn't be any side effect.",
            "LEAN UP also does not suppress your appetite and do not have laxative effect.",
            "Each serving of LEAN UP contains a small amount of caffeine, do take note if you're sensitive towards caffeinated products."
          ]
        },
        {
          "qn": "Is it safe for breastfeeding moms?",
          "ans": [
            "LEAN UP is made with plant-based ingredients and have been pasteurised. It has been tested for toxicology so it should be safe for breastfeeding moms.",
            "Each serving does contain 25mg of naturally-occurring caffeine from guarana fruits. The daily recommended caffeine limit for breastfeeding is usually 300mg and each cup of coffee usually contains 94mg. Do take note of your caffeine intake if you're planning to take both coffee and LEAN UP or avoid it if you have a sensitive stomach towards caffeine."
          ]
        },
        {
          "qn": "When and how often should I drink LEAN UP?",
          "ans": [
            "We recommend taking LEAN UP once daily, 30 minutes before a meal."
          ]
        },
        {
          "qn": "Will there be a rebound if I were to stop taking LEAN UP?",
          "ans": [
            "A 1-month follow-up study was conducted on the formula after 16 weeks of consumption. The follow-up study shows that subjects continue to have higher metabolic rate than the placebo group, hence suggesting LEAN UP's long-lasting effects"
          ]
        }
      ]
    }
  },
  "Glow+": {
    "feature": {
      "desc": {
        "items": [
          "Formulated with Gut-Skin Microbiome Advancement™. Clinically proven.",
          "Contains lactose-based prebiotics to strengthen the gut and improve skin health for brighter skin",
          "Prevents hyperpigmentation and lowers skin sensitivity",
          "Contains 2500mg of Type 1 Hydrolysed Marine Collagen",
          "No added artificial concentrates or colouring",
          "In the form of fruit nectar, just add water to enjoy",
          "Halal and ISO22000 certified"
        ]
      }
    },
    "recipe": {
      "items": []
    },
    "productDetails": {
      "desc": {
        "items": [
          "GLOW+ UV Protection Collagen Juice is made with a clinically proven formula to prevent hyperpigmentation and reduce skin sensitivity to sun damage. It offers an increase of hydration and a higher protection against photoaging resulting in clearer and brighter looking skin.",
          "Prevents Hyperpigmentation: Up to 68% Melanin Index Reduction demonstrates brighter skin and lesser dark spots.",
          "Lesser Redness On Skin: Up to 76% More Reduction In Erythema Index.",
          "Improves Skin Hydration: Up to 57% More Water Retention In The Skin."
        ]
      },
      "targetCustomer": {
        "items": [
          "For those who are concerned about about hyperpigmentation and dark spots",
          "Busy individuals looking for hassle-free skin improvement method",
          "Anyone looking for a new way to enjoy collagen drinks"
        ]
      },
      "keyIngredient":  {
        "items": [
          {
            "title": "GUT-SKIN MICROBIOME ADVANCEMENT™",
            "desc": "Good skin starts from a healthier microbiome. Gut-skin Microbiome Advancement™ contains prebiotics that helps to rebalance the ratio of Lactobacillus and Bifidobacteria of the gut microflora leading to lower inflammation which directly lowers skin sensitivity to sun damage and a significant reduction of melanin production, contributing to fewer dark spots."
          },
          {
            "title": "TYPE 1 HYDROLYSED MARINE COLLAGEN",
            "desc": "Collagen facilitates the growth of healthy skin, nails and hair and plays a key role in maintaining skin elasticity, suppleness and moisture. We used Type 1 Hydrolysed Marine collagen to maximise absorption to help you effectively achieve your natural skin's glow."
          }
        ]
      }
    },
    "faq": {
      "items": [
      ]
    }
  },
  "Glow": {
    "feature": {
      "desc": {
        "items": [
          "Contains 5000mg Type 1 Hydrolysed Marine Collagen",
          "Blended with natural fruits for great taste and benefits",
          "No added artificial concentrate or colouring",
          "Sweetened naturally with Stevia",
          "Just add water to fruit nectar to enjoy!",
          "Halal and ISO22000 certified"
        ]
      }
    },
    "recipe": {
      "items": []
    },
    "productDetails": {
      "desc": {
        "items": [

        ]
      },
      "targetCustomer": {
        "items": [
          "Anyone above 20 looking to improving their skin with natural ingredients",
          "Busy individuals looking for a functional, long-lasting and natural way to improve their skin conditions",
          "Anyone who is looking for an effective collagen drink with no fishy taste and sugar"
        ]
      },
      "keyIngredient":  {
        "items": [
          {
            "title": "TYPE 1 HYDROLYSED MARINE COLLAGEN",
            "desc": "Collagen facilitates the growth of healthy skin, nails and hair and plays a key role in maintaining skin elasticity, suppleness and moisture. We used Type 1 Hydrolysed Marine collagen to maximise absorption to help you effectively achieve your natural skin's glow."
          },
          {
            "title": "STEVIA",
            "desc": "Sweetened naturally with Stevia, mother nature's 0-calorie plant sweetener, documented to have medicinal purposes such as anti-microbial, antidiarrheal, anti-inflammatory, diueretic and immunomodulatory actions."
          }
        ]
      }
    },
    "faq": {
      "items": [
      ]
    }
  },
  "Booster": {
    "feature": {
      "desc": {
        "items": [
        ]
      }
    },
    "recipe": {
      "items": []
    },
    "productDetails": {
      "desc": {
        "items": [

        ]
      },
      "targetCustomer": {
        "items": [
          "Busy individuals wants a fuss-free and convenient way to enjoy Doki Juice on-the-go.",
          "Those struggling to meet their daily nutrition needs"
        ]
      },
      "keyIngredient":  {
        "items": [
          {
            "title": "STEVIA",
            "desc": "Sweetened naturally with Stevia, mother nature's 0-calorie plant sweetener, documented to have medicinal purposes such as anti-microbial, antidiarrheal, anti-inflammatory, diueretic and immunomodulatory actions."
          }
        ]
      }
    },
    "faq": {
      "items": [
      ]
    }
  },
  "Cadence": {
    "feature": {
      "desc": {
        "items": [
          "Made with premium tea leaves ",
          "Improves mental cognition and boosts alertness ",
          "Formulated with electrolytes for better hydration",
          "Blended with real fruits for great taste and benefits",
          "No added artificial concentrate or colouring",
          "Contains naturally occurring caffeine",
          "Sweetened naturally with Stevia",
          "Halal and ISO22000 certified"
        ]
      }
    },
    "recipe": {
      "items": []
    },
    "productDetails": {
      "desc": {
        "items": [
          "CADENCE Energising Tea Juice is made naturally with premium tea leaves and electrolytes to bring you a boost of alertness, hydration and enjoyment. It contains natural-occurring caffeine that improves your mental function and takes away your mid-day fatigue."
        ]
      },
      "targetCustomer": {
        "items": [
          "Anyone looking to improve their mental function and hydrate their body at the same time",
          "Anyone looking for a healthier caffeine alternative",
          "Great for on-the-go working adults with 9-6 jobs looking to refresh their mid-day fatigue and boost their alertness"
        ]
      },
      "keyIngredient":  {
        "items": [
          {
            "title": "ELECTROLYTES",
            "desc": "Known to supercharge your hydration, electrolytes step in to regulate our hydration levels needed in our body. Water comprises up to 60% of our body, whenever we lose a small amount of water in our body, it can't perform all its essential functions. Electrolyte imbalances often occur due to dehydration which can cause symptoms such as fatigue, muscle weakness and headaches."
          },
          {
            "title": "STEVIA",
            "desc": "Sweetened naturally with Stevia, mother nature's 0-calorie plant sweetener, documented to have medicinal purposes such as antimicrobial, antidiarrheal, anti-inflammatory, diuretic and immunomodulatory actions."
          }
        ]
      }
    },
    "faq": {
      "items": [
      ]
    }
  },
  "Hype": {
    "feature": {
      "desc": {
        "items": [
          "Formulated with a LipidMR3™ Technology",
          "Made with a clinically proven formula backed by multiple studies",
          "Helps to increase your energy levels and keeps you more active",
          "Elevates fitness performance with the release of energy converted from free fatty acid",
          "Strong bitterness taste from Dark Cacao with a hint of sweetness from real Strawberries",
          "Add 180ml of milk of choice to enjoy",
          "No added artificial concentrate or colouring",
          "Contains naturally-occurring caffeine",
          "Halal and ISO22000 certified"
        ]
      }
    },
    "recipe": {
      "items": []
    },
    "productDetails": {
      "desc": {
        "items": [
          "HYPE Fitness Juice is formulated with LipidMR3™, a clinically proven formula and made with real fruits to help you build your lean muscles more efficiently while creating a synergy of bioactive polyphenols that accelerates caloric burn rate for increased energy output and performance.",
          "Crafted with real fruits to give you the best taste, HYPE is for those looking to elevate their fitness routine and performance through building their lean muscles. They are recommended to be consumed 60-90 minutes before a workout."
        ]
      },
      "targetCustomer": {
        "items": [
          "Anyone looking for an effective and safe way to build lean muscle and improve their fitness routine",
          "Individuals looking for a functional and natural way to reduce free fatty acids stored in their fat reservoirs",
          "Anyone looking to rebuild their body composition to a higher Lean-Muscle-To-Fat ratio"
        ]
      },
      "keyIngredient":  {
        "items": [
          {
            "title": "LipidMR3™",
            "desc": "Patented technology from France, it uses natural extracts to rebuild long-term body composition, release energy and reduce free fatty acids stored in the fat reservoir of your cells."
          },
          {
            "title": "DARK CACAO",
            "desc": "Dark Cacao is known to improve the level of nitric oxide in our blood which helps to relax our arteries, blood vessels and increase muscle mass. Originated in South America about 5,000 years ago, Cacao is packed with flavonoids which are nutrients known to lower blood pressure and improve blood flow to the brain and heart, as well as reduce inflammation in our body."
          },
          {
            "title": "STEVIA",
            "desc": "Sweetened naturally with Stevia, mother nature's 0-calorie plant sweetener, documented to have medicinal purposes such as antimicrobial, antidiarrheal, anti-inflammatory, diuretic and immunomodulatory actions."
          }
        ]
      }
    },
    "faq": {
      "items": [
      ]
    }
  }
};


// =======================
// Do not edit below
// ===
export default {

  getData() {
    return obj;
  }
}
