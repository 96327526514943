import $ from 'jquery';
import GTM from 'doki-js/gtm';
import ScrollMagic from 'scrollmagic';

var smc = null;
var smNavbar, smSecNavbar = null;
var isNavbarVisible = true;
var isScrollToUpdateNavbarMode = false;

var pageClassName = '';

export default {

  init(_pageClassName, isShowUnderBar=false) {

    pageClassName = _pageClassName;

    if (isShowUnderBar == true) {
      $('.nav .underbar-wrapper').addClass('show');
    }

    // Setup Scroll trigger for navbar
    if (smc == null) {
      smc = new ScrollMagic.Controller;
    }

    smNavbar = new ScrollMagic.Scene({
      triggerElement: '.nav .trigger--nav',
      triggerHook: 0
    })
    .addTo(smc)
    .on('enter', function(e) { //scroll down pass trigger to hide navbar
      hideNavbar();
      isScrollToUpdateNavbarMode = true;
    })
    .on('update', function(e) { //scroll up to show navbar and vice versa
      if (isScrollToUpdateNavbarMode) {
        if (smc.info("scrollDirection") == 'FORWARD') {
          if (isNavbarVisible) {
            hideNavbar();
          }
        }
        else {
          if (!isNavbarVisible) {
            showNavbar();
          }
        }
      }
    })
    .on('leave', function(e) { //scroll up pass trigger to show navbar
      showNavbar(true);
      isScrollToUpdateNavbarMode = false;
    });

    // Check if page has secondary navbar
    var elSecNavbar = $('.' + pageClassName + ' .template--secondary-navbar');
    if(elSecNavbar.length > 0) {

      // insert secondary navbar
      var html = elSecNavbar.html();
      elSecNavbar.empty();
      $('.nav .placeholder--secondary-navbar').append(html);

      // Setup Scroll trigger for secondary navbar
      smSecNavbar = new ScrollMagic.Scene({
        triggerElement: '.' + pageClassName + ' .trigger--secondary-navbar',
        triggerHook: 0
      })
      .addTo(smc)
      .on('enter', function(e) { //scroll down pass trigger to hide secondary navbar
        $('.nav .placeholder--secondary-navbar').addClass('show');
      })
      .on('leave', function(e) { //scroll up pass trigger to show secondary navbar
        $('.nav .placeholder--secondary-navbar').removeClass('show');
      });
    }



    // Check if page has secondary underbar
    var elSecUnderbar = $('.' + pageClassName + ' .template--secondary-underbar');
    if(elSecUnderbar.length > 0) {

      // insert elSecUnderbar navbar
      var html = elSecUnderbar.html();
      elSecUnderbar.empty();
      $('.nav .placeholder--secondary-underbar').append(html);

      // setTimeout(function(){ 
      //   var elSecondaryUnderbar = $('.nav .placeholder--secondary-underbar > div');
      //   var underbarHeight = elSecondaryUnderbar.outerHeight();

      //   var newHeight = $('.nav .navbar').height() + parseInt(underbarHeight);
      //   $('.nav .navbar').height(newHeight); //update height so that hide transistion will include the underbar height
      // }, 1000);
    }

    // Open menu modal
    $('.nav .menu-btn').click(function (){
      $('.nav .menu-modal').addClass('open');
      $('.' + pageClassName).addClass('invisible');
      $('body').addClass('no-scroll');
    });

    // Close menu modal
    $('.nav .close-menu-btn').click(function (){
      $('.nav .menu-modal').removeClass('open');
      $('.' + pageClassName).removeClass('invisible');
      $('body').removeClass('no-scroll');
    });

    // Open location modal
    $('.nav .location-btn').click(openLocationModal);

    // Close location modal
    $('.nav .close-location-btn').click(function (){
      $('.nav .location-modal').removeClass('open');
      $('.' + pageClassName).removeClass('invisible');
      $('body').removeClass('no-scroll');
    });

    trackGTM();
  },

  setBagItemCount(count) {
    if (count > 0) {
      $('.nav .navbar__bag .item-count').text(count);
      $('.nav .navbar__bag').addClass('has-item');
    }
    else {
      $('.nav .navbar__bag .item-count').text('');
      $('.nav .navbar__bag').removeClass('has-item');
    }
  },

  uninit() {
    untrackGTM();

    $('.nav .menu-btn').off();
    $('.nav .close-menu-btn').off();
    $('.nav .location-btn').off();
    $('.nav .close-location-btn').off('click', openLocationModal);

    if (smc) smc.destroy();
    smc = null;
  }
}

function openLocationModal() {
  $('.nav .location-modal').addClass('open');
  $('.' + pageClassName).addClass('invisible');
  $('body').addClass('no-scroll');
}


function trackGTM() {

  // Track clicks to shop page
  $('.nav a[data-gtm-click-action="shop"]').on('click', function(e) {
    // e.preventDefault();
    var el = $(e.currentTarget);
    GTM.trackClickShop(el.attr('data-gtm-product-series'), el.attr('data-gtm-section-name'));
  });

  // Track clicks to bag page
  $('.nav a[data-gtm-click-action="bag"]').on('click', function(e) {
    // e.preventDefault();
    var el = $(e.currentTarget);
    GTM.trackClickBag(el.attr('data-gtm-section-name'));
  });

  // Track click location/country
  $('.nav .location-btn').click(function (){
    GTM.trackClickCountry(null, 'Nav');
  });
  $('.nav a[data-gtm-click-action="country"]').on('click', function(e) {
    // e.preventDefault();
    var el = $(e.currentTarget);
    GTM.trackClickCountry(el.attr('data-gtm-country-short-code'), 'Nav');
  });
}

function untrackGTM() {
  // Untrack clicks to shop page
  $('.nav a[data-gtm-click-action="shop"]').off('click', () => {});

  // Untrack clicks to bag page
  $('.nav a[data-gtm-click-action="bag"]').off('click', () => {});

  // Untrack click location/country
  $('.nav .location-btn').off('click', () => {});
  $('.nav a[data-gtm-click-action="country"]').off('click', () => {});

}

function hideNavbar() {

  $('.nav .navbar').addClass('hide');
  $('.nav .placeholder--secondary-underbar').addClass('hide');
  isNavbarVisible = false;
}

function showNavbar(isShowSecondaryUnderbar=false){
  $('.nav .navbar').removeClass('hide');
  if(isShowSecondaryUnderbar)
    $('.nav .placeholder--secondary-underbar').removeClass('hide');
  isNavbarVisible = true;
}
